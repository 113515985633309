import React from 'react';
import { DefaultLayout } from '@/components/layouts';
import { DeclarationMessageUI } from './DeclarationMessageUI';

export const DeclarationMessagePage: React.FC = () => {
  return (
    <DefaultLayout>
      <DeclarationMessageUI />
    </DefaultLayout>
  );
};
