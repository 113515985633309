import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './changePassword.css';
import { employeeChangePassword, employeeVerifyPassword, fetchEmployeeProfile } from '../../../../../services/employee';
import { AccountContext, useAppDispatch } from '@/store';
import { ActionButton } from '@/components/ActionButton';
import { Spacer } from '@/components/Spacer';
import { AppTextField } from '@/components/AppTextField';
import { Box, IconButton, InputAdornment, Typography } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { ChangePasswordUIBreadcrumbs } from './ChangePasswordUIBreadcrumbs';
import { EmployeeSectionCard } from '@/components/EmployeeSectionCard';
import { useToast } from '@/components/Toast';
import { useAppVariant } from '@/hooks/useAppVariant';

function ChangePasswordUI() {
  const toast = useToast();
  const appVariant = useAppVariant();
  const dispatch = useAppDispatch();

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const navigate = useNavigate();

  const handleCheckPassword = async () => {
    if (oldPassword) {
      const res = await employeeVerifyPassword({
        password: oldPassword,
      });
      return !!res?.data?.isValid;
    } else {
      return false;
    }
  };

  const handleClick = async (event: any) => {
    event.preventDefault();
    const valid = await handleCheckPassword();
    try {
      if (valid) {
        if (newPassword !== confirmPassword) {
          toast.present({ message: 'Attention, les mots de passe ne correspondent pas.', severity: 'error' });
          return;
        }
        employeeChangePassword({
          password: newPassword,
        }).then(async () => {
          await fetchEmployeeProfile().then((value) => {
            dispatch(AccountContext.actions.setUser(value.data));
            toast.present({ message: 'Votre mot de passe à bien été modifié', severity: 'success' });
            navigate('/profil', { replace: true });
          });
        });
      } else {
        toast.present({ message: 'Le mot de passe est erroné', severity: 'error' });
      }
    } catch {
      toast.present({ message: 'Une erreur est survenue', severity: 'error' });
    }
  };

  return (
    <Box display={'flex'} justifyContent={'space-around'}>
      <Box maxWidth={1080} width={'100%'}>
        <Box pl={appVariant.small ? 2 : 4} pr={appVariant.small ? 2 : 4}>
          <Spacer spacing={appVariant.small ? 8 : 1} />

          {appVariant.small && (
            <Box>
              <ChangePasswordUIBreadcrumbs />
              <Spacer spacing={2} />
            </Box>
          )}

          <Typography fontWeight={600} fontSize={appVariant.small ? 32 : 24} lineHeight={'normal'}>
            Modifier
            {appVariant.small ? ' ' : <br />}
            mon mot de passe
          </Typography>

          <Spacer spacing={appVariant.small ? 5 : 3} />
        </Box>

        <form onSubmit={handleClick}>
          <Box pl={appVariant.small ? 2 : 4} pr={appVariant.small ? 2 : 4}>
            <EmployeeSectionCard
              sx={(theme) => ({
                maxWidth: appVariant.small ? 450 : undefined,
                margin: appVariant.small ? 'inherit' : 'auto',
              })}
            >
              <AppTextField
                dense
                label={'Mot de passe actuel'}
                type={'password'}
                value={oldPassword}
                InputProps={{
                  type: showOldPassword ? 'text' : 'password',
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton size="small" onClick={() => setShowOldPassword(!showOldPassword)}>
                        {showOldPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  sx: (theme) => ({ paddingRight: theme.spacing(1) }),
                }}
                onChange={(_, value) => {
                  setOldPassword(value);
                }}
                noHelper
              />

              <Spacer spacing={3} />

              <AppTextField
                dense
                label={'Nouveau mot de passe'}
                type={'password'}
                value={newPassword}
                InputProps={{
                  type: showNewPassword ? 'text' : 'password',
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton size="small" onClick={() => setShowNewPassword(!showNewPassword)}>
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  sx: (theme) => ({ paddingRight: theme.spacing(1) }),
                }}
                onChange={(_, value) => {
                  setNewPassword(value);
                }}
                noHelper
              />

              <Spacer spacing={3} />

              <Typography fontSize={12}>
                Votre mot de passe doit être composé de 8 caractères minimum, au moins une lettre majuscule, au moins
                une lettre minuscule, et une valeur numérique.
              </Typography>
              <Spacer spacing={1} />
              <AppTextField
                label={'Confirmation du mot de passe'}
                type={'password'}
                value={confirmPassword}
                InputProps={{
                  type: showConfirmPassword ? 'text' : 'password',
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton size="small" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  sx: (theme) => ({ paddingRight: theme.spacing(1) }),
                }}
                onChange={(_, value) => {
                  setConfirmPassword(value);
                }}
                noHelper
              />
              <Spacer spacing={3} />

              <ActionButton color="primary" type="submit" actionName="validate" startIcon={null} fullWidth />
            </EmployeeSectionCard>
          </Box>
        </form>

        <Spacer spacing={3} />
      </Box>
    </Box>
  );
}

export default ChangePasswordUI;
