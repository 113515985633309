import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchEmployeeProfile } from '../../../services/employee';
import isAuthorized from '../../../hooks/isAuthorized';
import { AccountContext, useAppDispatch, useAppSelector } from '@/store';
import { AxiosResponse } from 'axios';
import { useToast } from '@/components/Toast';
import { Box, Grid, Typography } from '@mui/material';
import { Spacer } from '@/components/Spacer';
import { ProfilFormUIFormContent } from './ProfilFormUIFormContent';
import { ProfilFormUIDesktopHelper } from './ProfilFormUIDesktopHelper';
import { ProfilFormUIDesktopBreadcrumbs } from './ProfilFormUIDesktopBreadcrumbs';
import { ProfilFormUISkeleton } from './ProfilFormUISkeleton';
import { useAppVariant } from '@/hooks/useAppVariant';

export function ProfilFormUI() {
  const accountContext = useAppSelector((state) => state.accountContext);

  const { pathname } = useLocation();
  const appVariant = useAppVariant();
  const toast = useToast();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const onLogout = (message: string, severity: 'success' | 'error' = 'error') => {
    dispatch(AccountContext.logout());
    toast.present({ message, severity });
    return navigate('/login', { state: { fromPath: '/profil' } });
  };

  const onUpdated = async () => {
    setIsSubmitting(true);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    await findEmployee();
    setIsSubmitting(false);
  };

  async function findEmployee() {
    let profilValue: AxiosResponse;

    try {
      profilValue = await fetchEmployeeProfile();
      if (profilValue?.data?.last_change_password) return dispatch(AccountContext.actions.setUser(profilValue.data));
      else onLogout('Veuillez vous reconnectez');
    } catch {
      onLogout('Erreur de connexion au serveur');
    }
  }

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    if (isAuthorized(pathname)) findEmployee();
    else navigate('/login', { state: { fromPath: '/profil' } });
  }, []);

  return (
    <Box display={'flex'} justifyContent={'space-around'}>
      <Box maxWidth={1080} width={'100%'}>
        <Box pl={appVariant.small ? 2 : 4} pr={appVariant.small ? 2 : 4}>
          <Spacer spacing={appVariant.small ? 8 : 1} />

          {appVariant.small && (
            <Box>
              <ProfilFormUIDesktopBreadcrumbs />
              <Spacer spacing={2} />
            </Box>
          )}

          <Typography fontWeight={600} fontSize={appVariant.small ? 32 : 24} lineHeight={'normal'}>
            Informations
            {appVariant.small ? ' ' : <br />}
            personnelles
          </Typography>

          <Spacer spacing={appVariant.small ? 5 : 3} />

          <Grid container spacing={12}>
            <Grid item xs={12} md={7}>
              {!isSubmitting && !accountContext.loading && <ProfilFormUIFormContent onUpdated={onUpdated} />}
              {isSubmitting || (accountContext.loading && <ProfilFormUISkeleton />)}
            </Grid>

            {appVariant.small && (
              <Grid item xs={12} md={5}>
                <ProfilFormUIDesktopHelper />
              </Grid>
            )}
          </Grid>
        </Box>

        <Spacer spacing={4} />
      </Box>
    </Box>
  );
}
