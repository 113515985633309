/**
 * Ce input est une representation du TextField de MUI
 * Pour les différentes partie d'un TextField custom :
 * @see https://mui.com/material-ui/react-text-field/#components
 *
 * Pour des TextField de type Select
 * @path ../AppSelect
 */
import * as React from 'react';
import {
  Box,
  FormControl,
  FormControlProps,
  FormHelperText,
  InputBaseProps,
  InputLabelProps,
  InputProps as MUIInputProps,
  styled,
  TextFieldProps,
} from '@mui/material';
import { ForwardedRef } from 'react';
import { InputProps, useInput } from '@/hooks/useInput';
import { AppInputLabel } from '@/components/AppInputLabel';
import { AppInput, AppInputDense } from '@/components/AppInput';
import { Spacer } from '../Spacer';

type InputValue = string;

export interface AppTextFieldProps
  extends Omit<TextFieldProps, keyof InputProps<HTMLInputElement | HTMLTextAreaElement, InputValue>>,
    InputProps<HTMLInputElement | HTMLTextAreaElement, InputValue> {
  InputProps?: MUIInputProps;
  LabelProps?: InputLabelProps;
  variant?: FormControlProps['variant'];
  dense?: boolean;
  /** @deprecated Attendant le refacto des input */
  noHelper?: boolean;
}

export const AppTextField = React.forwardRef(function AppTextField(
  props: AppTextFieldProps,
  ref: ForwardedRef<Element>,
) {
  const TextFieldProps: Pick<AppTextFieldProps, 'disabled' | 'readOnly' | 'required' | 'error'> = {
    disabled: props.disabled,
    error: props.error,
    readOnly: props.readOnly,
    required: props.required,
  };

  const { useRootProps, useSlotProps } = useInput({ componentName: 'AppTextField', props, ref });
  const {
    label,
    placeholder,
    helperText,
    InputProps,
    LabelProps,
    multiline,
    maxRows,
    variant,
    dense,
    noHelper,
    ...rootProps
  } = useRootProps(props);
  const inputSlotProps = {
    ...useSlotProps(),
    multiline,
    maxRows,
  };
  const handleChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = React.useCallback(
    (event) => {
      inputSlotProps.onChange(event, event.target.value);
    },
    [inputSlotProps.onChange],
  );

  const formControlProps: FormControlProps = { ...rootProps, ...TextFieldProps };
  const labelProps: InputLabelProps = { shrink: true, ...LabelProps };
  const inputProps: MUIInputProps = {
    ...inputSlotProps,
    ...TextFieldProps,
    ...InputProps,
    onChange: handleChange,
  };

  const variantInputProps: Pick<InputBaseProps, 'sx'> =
    variant == 'standard'
      ? {
          sx(theme) {
            return {
              borderColor: theme.shape.borderColor,
              boxShadow: theme.shadows[1],
            };
          },
        }
      : {};

  return (
    <FormControl variant="standard" focused {...formControlProps} sx={{ width: '100%' }}>
      <AppInputLabel shrink {...labelProps}>
        {label}
      </AppInputLabel>

      {dense ? (
        <AppInputDense
          autoComplete={rootProps.autoComplete}
          {...inputProps}
          {...variantInputProps}
          placeholder={placeholder}
        />
      ) : (
        <AppInput
          autoComplete={rootProps.autoComplete}
          {...inputProps}
          {...variantInputProps}
          placeholder={placeholder}
        />
      )}

      {!noHelper && !dense && <HelperText>{helperText ?? ' '}</HelperText>}
    </FormControl>
  );
});

const HelperText = styled(FormHelperText)(({ theme }) => ({
  marginTop: theme.spacing(0.5),
  marginLeft: theme.spacing(0.5),
  fontSize: 12,
  lineHeight: 'normal',
}));
