import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ActionButton } from '@/components/ActionButton';
import { Box, BoxProps, Typography } from '@mui/material';
import { Spacer } from '@/components/Spacer';
import { CreateRounded, FeaturedPlayListRounded, KeyboardArrowRightRounded } from '@mui/icons-material';
import { AccountCircleOutlined } from '@/components/AppIcon';
import { AccountContext, useAppDispatch } from '@/store';
import { useToast } from '@/components/Toast';

export interface ProfilUIMobileContentProps extends BoxProps {}

export function ProfilUIMobileContent(props: ProfilUIMobileContentProps) {
  const { ...boxProps } = props;

  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onChange = (to: string) => {
    navigate(to, {
      state: { data: to, page: 'Login', fromPath: '/profil' },
    });
  };

  const onLogout = () => {
    dispatch(AccountContext.logout());
    toast.present({ message: 'Vous avez été déconnecté', severity: 'success' });
    return navigate('/login', { state: { fromPath: '/profil' } });
  };

  const renderButton = (type: 'info' | 'password' | 'dossier') => {
    const routePath = type === 'info' ? '/profil/edit' : type === 'password' ? '/changepassword' : '/dossier';
    const title =
      type === 'info' ? 'Informations personnelles' : type === 'password' ? 'Changer de mot de passe' : 'Mon dossier';
    const icon =
      type === 'info' ? (
        <AccountCircleOutlined sx={{ mr: 2 }} />
      ) : type === 'password' ? (
        <CreateRounded sx={{ mr: 2 }} />
      ) : (
        <FeaturedPlayListRounded sx={{ mr: 2 }} />
      );

    return (
      <ActionButton
        fullWidth
        variant="text"
        label={
          <Box fontSize={16} lineHeight={1.5} fontWeight={400}>
            {title}
          </Box>
        }
        color="tertiary"
        startIcon={icon}
        endIcon={<KeyboardArrowRightRounded />}
        sx={{ textAlign: 'left' }}
        onClick={() => onChange(routePath)}
      />
    );
  };

  return (
    <Box {...boxProps}>
      <Box pl={4} pr={4}>
        <Typography fontSize={20} fontWeight={600} lineHeight={'normal'}>
          Paramètres
        </Typography>
        <Spacer spacing={1} />
      </Box>

      <Box pl={3} pr={3}>
        <Spacer spacing={2.5} />
        {renderButton('info')}
        <Spacer spacing={1.5} />
        {renderButton('password')}
        <Spacer spacing={1.5} />
        {renderButton('dossier')}
        <Spacer spacing={6.5} />
      </Box>

      <Box pl={4} pr={4}>
        <ActionButton fullWidth color="secondary" label={'Se déconnecter'} onClick={onLogout} />
        <Spacer spacing={1} />
      </Box>
    </Box>
  );
}
