import React, { useState, useEffect } from 'react';
import { Navbar } from '@/components/Navbar';
import Footer from '../Footer/Footer';
import ImgServiceKerijblanc from '../../assets/service-logokerij-blanc.jpg';
import ImgRetour from '../../assets/icon-retour.png';
import './ServiceDetails.css';
import { useParams, useNavigate } from 'react-router-dom';
import ReactLoading from 'react-loading';
import { fetchSelectedService } from '../../services/employee';
import { StatusCodes } from 'http-status-codes';
import { AccountContext, useAppSelector } from '@/store';
import { Service, ServiceDocumentType } from '@/types';
import {
  Link,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper,
  Typography,
  IconButton,
  Button,
  useTheme,
  Tooltip,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spacer } from '@/components/Spacer';

export function ServiceDetails() {
  const navigate = useNavigate();
  const user = useAppSelector(AccountContext.getUser);
  const [loadingState, setLoadingState] = useState(true);
  const [selectedService, setSelectedService] = useState<Service | undefined>();
  const [access, setAccess] = useState(false);
  const { id: idService } = useParams();
  const logoURL = selectedService?.service_documents?.find((doc) => doc.type === ServiceDocumentType.Logo)?.url;
  const documents = selectedService?.service_documents?.filter((doc) => doc.type === ServiceDocumentType.Referential);
  const loadService = async (idService: string) => {
    if (user) {
      setLoadingState(true);

      try {
        const response = await fetchSelectedService({
          idService,
        });
        if (response.status === StatusCodes.OK) {
          setSelectedService(response.data.services);
          setAccess(true);
        }
      } finally {
        setLoadingState(false);
      }
    }
  };

  const theme = useTheme();

  const renderSection = (title: string, children: React.ReactNode) => {
    const subheader = true; // Change this if we want to display a subheader
    const backgroundColor = undefined; //theme.palette.grey[100];
    return (
      <Paper elevation={0} style={{ borderRadius: theme.shape.borderRadius, backgroundColor }}>
        <List subheader={subheader ? <ListSubheader style={{ backgroundColor }}>{title}</ListSubheader> : null}>
          {children}
        </List>
      </Paper>
    );
  };

  const renderField = (
    icon: undefined | React.ReactElement,
    label: string,
    value: React.ReactNode,
    other: { href?: string; tooltip?: string; clipboardText?: string; labelEnabled?: boolean } = {},
  ) =>
    value ? (
      <ListItem disablePadding={true}>
        <Tooltip title={other.tooltip ?? label}>
          <ListItemButton
            {...(other.href
              ? { component: Link, href: other.href, target: '_blank', rel: 'noreferrer' }
              : {
                  onClick: () => {
                    if (other.clipboardText != null) {
                      navigator.clipboard.writeText(other.clipboardText);
                    }
                  },
                })}
          >
            {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
            <ListItemText
              disableTypography
              primary={other.labelEnabled ? <Typography variant="body1">{label}</Typography> : undefined}
              secondary={
                <Typography variant={other.labelEnabled ? 'body2' : 'body1'} component="p">
                  {value}
                </Typography>
              }
            />
          </ListItemButton>
        </Tooltip>
      </ListItem>
    ) : null;

  useEffect(() => {
    if (idService) {
      loadService(idService);
    }
  }, [user, idService]);

  const hasAddress =
    selectedService?.address ||
    selectedService?.address_complement ||
    selectedService?.zipcode ||
    selectedService?.city;
  const addressText = hasAddress
    ? [
        selectedService?.address ?? '',
        selectedService?.address_complement ?? '',
        `${selectedService?.zipcode ?? ''} ${selectedService?.city ?? ''}`,
      ]
        .flatMap((value) => (value.length > 0 ? [value, '\n'] : []))
        .join('')
    : undefined;
  const hasSecondColumn = selectedService?.identifiant || selectedService?.password || selectedService?.promo;
  const columnWidth = hasSecondColumn ? 6 : undefined;

  return (
    <div>
      <Navbar />
      {loadingState ? (
        <div className="container-fluid container-loading">
          <h2>Veuillez patienter</h2>
          <div className="container">
            <ReactLoading type="spin" height={100} width={100} color="rgba(64, 201, 201, 1)"></ReactLoading>
          </div>
        </div>
      ) : (
        <div style={{ minHeight: '90vh' }}>
          <IconButton
            className="retour"
            onClick={() => {
              navigate('/services');
            }}
          >
            <img alt="retour" src={ImgRetour} />
          </IconButton>

          {access ? (
            <Container maxWidth="md">
              <Grid container spacing={2} padding={2}>
                <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <Typography variant="h4" color="primary">
                    {selectedService?.title}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="GrayText"
                    style={{ textTransform: 'capitalize', marginLeft: '1em' }}
                  >
                    {selectedService?.funding}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} padding={2}>
                <Grid
                  item
                  xs={12}
                  style={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    aspectRatio: 2 / 1,
                  }}
                >
                  <img
                    src={logoURL ?? ImgServiceKerijblanc}
                    alt={`${selectedService?.title} image`}
                    style={{
                      height: '100%',
                      width: '100%',
                      objectFit: 'contain',
                    }}
                    crossOrigin="anonymous"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} padding={2}>
                <Grid item>
                  <Typography variant="h5" color="primary">
                    {selectedService?.short_description}
                  </Typography>
                </Grid>
              </Grid>
              <Divider />
              {selectedService?.provider ? (
                <>
                  <Grid container spacing={2} padding={2}>
                    <Grid item>
                      <Typography variant="h5" color="primary">
                        Proposé par {selectedService?.provider}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                </>
              ) : null}
              <Grid container spacing={2} padding={2}>
                {selectedService?.description ? (
                  <>
                    <Grid item xs={12}>
                      <Typography variant="h5" color="primary">
                        Résumé
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={documents && documents.length > 0 ? 10 : 12}>
                      <Typography component="p" variant="body1" style={{ whiteSpace: 'pre-wrap' }}>
                        {selectedService?.description}
                      </Typography>
                    </Grid>
                    {documents && documents.length > 0 ? (
                      <Grid item xs={12} sm={2}>
                        {documents.map((document) => {
                          return (
                            <Tooltip title="En savoir plus" key={document.url}>
                              <IconButton
                                key={document.url}
                                component="a"
                                href={document.url}
                                download={true}
                                target="_blank"
                                sx={{ fontSize: '3rem', padding: '0.5em' }}
                              >
                                <FontAwesomeIcon icon="file-download"></FontAwesomeIcon>
                              </IconButton>
                            </Tooltip>
                          );
                        })}
                      </Grid>
                    ) : null}
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </>
                ) : null}
                {selectedService?.website ? (
                  <Grid item xs={12}>
                    <Button
                      component={Link}
                      href={selectedService?.website}
                      target="_blank"
                      color="primary"
                      variant="contained"
                      size="large"
                    >
                      Accéder au service
                    </Button>
                  </Grid>
                ) : null}
                <Grid item xs={12} md={columnWidth}>
                  {hasAddress || selectedService?.phone || selectedService?.email || selectedService?.website
                    ? renderSection(
                        'Informations',
                        <>
                          {renderField(
                            <FontAwesomeIcon icon="location-dot" />,
                            'Adresse',
                            hasAddress
                              ? [
                                  selectedService?.address ?? '',
                                  selectedService?.address_complement ?? '',
                                  `${selectedService?.zipcode ?? ''} ${selectedService?.city ?? ''}`,
                                ].flatMap((value) => (value.length > 0 ? [value, <br />] : []))
                              : null,
                            {
                              tooltip: 'Coordonnées postales du prestataire',
                              href: addressText
                                ? // `geo:0,0?q=${encodeURIComponent(addressText)}` Normally this is the standard but not well supported on all platform
                                  `https://maps.google.com/?q=${encodeURIComponent(addressText.replaceAll('\n', ','))}`
                                : undefined,
                              clipboardText: addressText,
                            },
                          )}
                          {renderField(<FontAwesomeIcon icon="phone" />, 'Contact', selectedService?.phone, {
                            tooltip: 'Téléphone du prestataire',
                            href: `tel:${selectedService?.phone}`,
                          })}
                          {renderField(<FontAwesomeIcon icon="envelope" />, 'E-mail', selectedService?.email, {
                            href: `mailto:${selectedService?.email}`,
                          })}
                          {renderField(<FontAwesomeIcon icon="globe" />, 'Site Internet', selectedService?.website, {
                            tooltip: 'Site web du prestataire',
                            href: `${selectedService?.website}`,
                          })}
                        </>,
                      )
                    : null}
                </Grid>
                {hasSecondColumn ? (
                  <Grid item xs={12} md={columnWidth}>
                    {renderSection(
                      'Accès',
                      <>
                        {renderField(<FontAwesomeIcon icon="user" />, 'Identifiant', selectedService?.identifiant, {
                          tooltip: 'Identifiant de connexion',
                          clipboardText: selectedService?.identifiant,
                        })}
                        {renderField(<FontAwesomeIcon icon="lock" />, 'Mot de passe', selectedService?.password, {
                          tooltip: 'Mot de passe de connexion',
                          clipboardText: selectedService?.password,
                        })}
                        {renderField(<FontAwesomeIcon icon="percent" />, 'Code promo', selectedService?.promo, {
                          tooltip: 'Code promo à remplir lors de la connexion',
                          clipboardText: selectedService?.promo,
                        })}
                      </>,
                    )}
                  </Grid>
                ) : null}

                <Grid item xs={12}>
                  <Spacer />
                </Grid>
              </Grid>
            </Container>
          ) : (
            <div className="container-fluid mt-5">
              <h1 className="question-form mb-5">Attention ! Vous n'avez pas l'autorisation d'accéder à cette page</h1>
            </div>
          )}
        </div>
      )}
      <Footer />
    </div>
  );
}
