import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ReactLoading from 'react-loading';
import moment from 'moment';
import './Activites.css';
import { ActivitesContent } from './ActivitiesContent';
import isAuthorized from '@/hooks/isAuthorized';
import { Notification, useAppDispatch, useAppSelector } from '@/store';
import { FormatDate } from '@/constants/date.constants';
import { AbsenceDate } from '@/components/DashboardRH/Absences/AbsenceDate';
import { ActionButton } from '../ActionButton';
import { Box } from '@mui/material';
import { Spacer } from '../Spacer';

export const Activites = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const location = useLocation();
  const pathname = location.pathname;
  const loading = useAppSelector(Notification.isLoading);
  const total = useAppSelector(Notification.getTotal);
  const notifications = useAppSelector(Notification.getList);
  const [offset, setOffset] = useState(0);

  const handleScroll = (state: any, location: any) => {
    const element = document.querySelector('#employee-notif');
    if (location.pathname === '/activites' && element && element.getBoundingClientRect().bottom <= window.innerHeight)
      total > state.length && setOffset(state.length);
  };
  const [notificationView, setNotificationView] = useState<Record<string, boolean>>({});

  const isOpen = (id: string) => Boolean(notificationView[id]);
  const toggleOpen = (id: string) =>
    setNotificationView((value) => ({
      ...value,
      [id]: !value[id],
    }));
  const markAsRead = (id: string) => dispatch(Notification.markAsRead({ id }));

  useEffect(() => {
    window.addEventListener('scroll', () => handleScroll(notifications, location));
    // eslint-disable-next-line unicorn/no-invalid-remove-event-listener
    return () => window.removeEventListener('scroll', () => handleScroll(notifications, location));
  }, [notifications, location]);

  useEffect(() => {
    if (isAuthorized(pathname)) {
      dispatch(Notification.load());
    }
  }, [offset]);

  useEffect(() => {
    if (!isAuthorized(pathname)) {
      navigate('/login', { replace: true, state: { fromPath: '/activites' } });
    }
  }, []);

  return (
    <>
      <div>
        <div className="container-fluid mt-5">
          {notifications.length === 0 ? (
            <div className="col-12 col-lg-10 px-3 text-center">
              <h1 className="question-form mb-5 text-center">Activités</h1>
              <div className="row col-12 mt-3 mb-3">
                <Link className="dashboard-choix-actif col-6" to={'/activites'}>
                  <span>Messages</span>
                </Link>

                <Link className="dashboard-choix-non-actif col-6" to={'/historiquesalarie'}>
                  <span>Actions</span>
                </Link>
              </div>
              <div className="col-12 mt-5 text-center">Aucune notification</div>
            </div>
          ) : (
            <div className="col-12 col-lg-10 text-center px-3">
              <h1 className="question-form mb-5 text-center">Activités</h1>
              <div className="row col-12 mt-3 mb-3">
                <Link className="dashboard-choix-actif col-6" to={'/activites'}>
                  <span>Messages</span>
                </Link>

                <Link className="dashboard-choix-non-actif col-6" to={'/historiquesalarie'}>
                  <span>Actions</span>
                </Link>
              </div>
              <div id="employee-notif">
                {notifications.length > 0 &&
                  notifications.map((notification, index) => {
                    return (
                      <div key={notification?.id || index}>
                        <div className="row-activites py-3 mb-3 bg-body rounded-3 row text-center">
                          <div className="col-12 col-lg-3 mb-1">
                            {moment(notification.last_reminder).format(FormatDate.FRENCH_DATE)}
                          </div>

                          <div className="col-12 col-lg-6 mb-1">
                            <div>
                              Absence <AbsenceDate value={notification.absence} />
                            </div>
                            {notification.type_notification}
                          </div>

                          {isOpen(notification.id) && (
                            <Box padding={2.5}>
                              <ActivitesContent notification={notification} />
                            </Box>
                          )}

                          {notification.type_notification !== 'Notification des services' && (
                            <Box className="col-12 col-lg-3" textAlign={'center'}>
                              <Spacer />
                              <ActionButton
                                actionName={isOpen(notification.id) ? 'close' : 'show'}
                                label={isOpen(notification.id) ? 'Fermer le message' : 'Voir le message'}
                                onClick={() => {
                                  markAsRead(notification.id);
                                  toggleOpen(notification.id);
                                }}
                              />
                              <Spacer />
                            </Box>
                          )}

                          {notification.type_notification === 'Notification des services' && (
                            <Box className="col-12 col-lg-3" textAlign={'center'}>
                              <Spacer />
                              <ActionButton
                                actionName={isOpen(notification.id) ? 'close' : 'show'}
                                label={isOpen(notification.id) ? 'Fermer le message' : 'Voir le message'}
                                onClick={() => {
                                  markAsRead(notification.id);
                                  toggleOpen(notification.id);
                                }}
                              />
                              <Spacer />
                            </Box>
                          )}
                          {!notification.is_checked && (
                            <div
                              style={{
                                position: 'relative',
                                display: 'flex',
                              }}
                            >
                              <div
                                style={{
                                  width: 10,
                                  height: 10,
                                  borderRadius: 10,
                                  backgroundColor: '#40C9C9',
                                  position: 'absolute',
                                  top: -70,
                                  right: 10,
                                }}
                              ></div>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
        </div>
      </div>
      {loading && (
        <div className="container-fluid container-loading">
          <h2>Veuillez patienter</h2>
          <div className="container-load">
            <ReactLoading type="spin" height={100} width={100} color="rgba(64, 201, 201, 1)"></ReactLoading>
          </div>
        </div>
      )}
    </>
  );
};
