import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { defaultLinks, footerLinksConfig } from './data';
import { FooterLink } from './FooterLink';

export const useFooterLinks = (): FooterLink[] => {
  const location = useLocation();
  const [footerLinks, setFooterLinks] = useState<FooterLink[]>(defaultLinks);

  useEffect(() => {
    const matchingPattern = Object.keys(footerLinksConfig).find((pattern) =>
      new RegExp(pattern).test(location.pathname),
    );
    if (matchingPattern) {
      setFooterLinks(footerLinksConfig[matchingPattern]);
    } else {
      setFooterLinks([]);
    }
  }, [location.pathname]);

  return footerLinks;
};
