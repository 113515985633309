import axios from '@/lib/axios';
import { Absence } from '@/types';

export const absenceFindAll = async (params: absenceFindAll.Parameters) => {
  const response = await axios.get<absenceFindAll.Return>(`/api/employee/absences`, { params });
  return response.data;
};

export namespace absenceFindAll {
  export type Parameters = undefined;
  export type Return = Array<Absence>;
}
