import React from 'react';
import { DefaultLayout } from '@/components/layouts';
import { DeclarationValidationUI } from './DeclarationValidationUI';

export const DeclarationValidationPage: React.FC = () => {
  return (
    <DefaultLayout>
      <DeclarationValidationUI />
    </DefaultLayout>
  );
};
