import React, { useEffect } from 'react';
import './Login.css';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { StatusCodes } from 'http-status-codes';
import { fetchEmployeeProfile } from '@/services/employee';
import { redirectToStoredPath, storeAuthData } from '@/utils/auth.utils';
import { RouterConfig } from '@/config/RouterConfig';
import { UserTypes } from '@/types/userTypes.enum';
import InputEmailValidate from '@/components/base/input/InputEmailValidate';
import InputPassword from '@/components/base/input/InputPassword';
import { FieldValues, useForm } from 'react-hook-form';
import { AccountContext, useAppDispatch, useAppSelector } from '@/store';
import { isLoginResponse, login } from '@/services/employee/auth/login';
import { Spacer } from '@/components/Spacer';
import { ActionButton } from '@/components/ActionButton';
import { Box, styled, Typography } from '@mui/material';
import { AppLogo } from '@/components/AppLogo';
import { EmployeeSectionCard } from '@/components/EmployeeSectionCard';
import { useToast } from '@/components/Toast';
import { useAppVariant } from '@/hooks/useAppVariant';

function LoginUI() {
  const appVariant = useAppVariant();
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [searchParameters] = useSearchParams();

  const user = useAppSelector(AccountContext.getUser);
  const isLoading = useAppSelector(AccountContext.isLoading);

  const { register, formState, handleSubmit } = useForm();
  const { errors, isValid, isSubmitting } = formState;

  const handleNavigatePreviousPage = () => {
    navigate(location.state?.fromPath ?? '/declaration');
  };

  const redirectToSignUp = () => {
    navigate('/signup', {
      state: {
        document: location.state ? location.state.document : null,
        page: location.state ? location.state.page : null,
      },
    });
  };

  const redirectToForgotPassword = () => {
    navigate('/forgotPassword', {
      state: {
        document: location.state ? location.state.document : null,
      },
    });
  };

  const redirectToConfirmation = (user: any) => {
    const stateValue = {
      hasAccount: false,
      password: null,
      originalPhone: user.infoEmployee?.employee_login?.phone,
      originalEmail: user.infoEmployee?.employee_login?.email,

      salarie: {
        id: user.infoEmployee?.id,
        email: user.infoEmployee?.employee_login?.email,
        description: user.infoEmployee?.description,
        id_business: user.infoEmployee?.business?.id,
        business: user.infoEmployee?.business,
        lastname: user.infoEmployee?.lastname,
      },
    };

    navigate('/createpassword', { state: stateValue });
  };

  const redirectAfterConnect = () => {
    return location.state?.fromPath ? handleNavigatePreviousPage() : navigate(`/declaration`, { replace: true });
  };

  const onSubmit = async (event: FieldValues) => {
    if (!isValid || isSubmitting) return;

    const { email, password } = event;
    const { data, status: responseStatus } = await login({ email, password });
    if (isLoginResponse(data)) {
      const { infoEmployee, status, token: newToken, exp, refreshToken } = data;
      if (status && status === 'pending') return redirectToConfirmation(data);
      storeAuthData({ userType: UserTypes.EMPLOYEE, token: newToken, exp, refreshToken });

      if (infoEmployee.employee_login?.last_change_password) {
        const { data } = await fetchEmployeeProfile();
        dispatch(AccountContext.actions.setUser(data));
        return redirectAfterConnect();
      } else navigate(RouterConfig.changepasswordPage().path);
    } else if (responseStatus === StatusCodes.UNAUTHORIZED)
      toast.present({ message: 'Mot de passe ou email incorrect', severity: 'error' });
    else if (responseStatus === StatusCodes.TOO_MANY_REQUESTS)
      toast.present({
        message: 'Vous avez envoyé beaucoup de requêtes.\nVous devez attendre 15 minutes pour reessayer',
        severity: 'error',
      });
    else toast.present({ message: 'Le compte ne semble pas exister', severity: 'error' });
  };

  useEffect(() => {
    if (user != null && !isLoading) redirectAfterConnect();
  }, [user, isLoading]);

  return (
    <Box pl={4} pr={4}>
      <Spacer spacing={appVariant.small ? 15 : 5} />

      <Box width={'100%'} textAlign={'center'}>
        <AppLogo variant="light-alt" />
      </Box>

      <Spacer spacing={appVariant.small ? 3 : 4} />

      <Typography fontWeight={600} fontSize={20} textAlign={'center'} lineHeight={'normal'}>
        Bonjour et bienvenue !
      </Typography>

      <Spacer spacing={3} />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <EmployeeSectionCard sx={(theme) => ({ maxWidth: appVariant.small ? 336 : undefined, margin: 'auto' })}>
            <InputEmailValidate dense label="E-Mail" register={register} error={errors?.['email']?.message} />
            <Spacer spacing={3} />
            <InputPassword
              dense
              label={
                <Box display={'flex'} width={'100%'} justifyContent={'space-between'}>
                  Mot de passe
                  <ButtonLink fontSize={12} mr={0.5} onClick={redirectToForgotPassword}>
                    {'Mot de passe oublié ?'}
                  </ButtonLink>
                </Box>
              }
              register={register}
              error={errors?.['password']?.message}
            />
            <Spacer spacing={3} />

            <ActionButton
              type="submit"
              color="primary"
              variant="contained"
              actionName="validate"
              label={'Se connecter'}
              startIcon={null}
              fullWidth
              submitting={isSubmitting}
            />
          </EmployeeSectionCard>

          <Spacer spacing={4} />

          <Box width={'100%'} display={'flex'} justifyContent={'center'}>
            <Typography fontSize={14} lineHeight={'normal'}>
              {' '}
              Nouveau sur KERij ?
            </Typography>
            <ButtonLink fontSize={14} pl={0.5} color="primary" onClick={redirectToSignUp}>
              Créer un compte
            </ButtonLink>
          </Box>
        </Box>
      </form>

      <Spacer spacing={1} />
    </Box>
  );
}

export default LoginUI;

const ButtonLink = styled(Typography)(({ theme, color }) => ({
  fontWeight: 400,
  lineHeight: 'normal',
  cursor: 'pointer',
  color: color ? undefined : theme.md3.sys.color.onSurfaceVariant,
  textDecoration: 'underline',
  ':hover': {
    color: theme.md3.sys.color.onSurface,
  },
}));
