import React from 'react';
import { DefaultLayout } from '../../../components/layouts';
import ForgetPasswordUI from './components/ForgetPasswordUI';

export const ForgetPasswordPage: React.FC = () => {
  return (
    <DefaultLayout navbar={false} mobileFooter>
      <ForgetPasswordUI />
    </DefaultLayout>
  );
};
