import React, { useMemo } from 'react';
import { AccountContext, Notification, useAppSelector } from '@/store';
import { BottomNavigation, Paper, PaperProps, styled } from '@mui/material';
import classNames from 'clsx';
import { NavbarMobileTab } from './NavbarMobileTab';
import { defineMenu } from './defineMenu';
import { Close, Menu as MenuIcon } from '../AppIcon';
import { useNavbarMobile } from './useNavbarMobile';
import { UseMUIDialogController } from '@/hooks/useMUIDialogController';

const NavbarPaper = styled(Paper)(({ theme }) => ({
  borderRadius: 0,
  borderTop: '1px solid',
  borderColor: theme.md3.sys.color.outlineVariant,
  paddingBottom: theme.spacing(1),
  bottom: 0,
  left: 0,
  right: 0,
  position: 'fixed',
}));

const componentName = 'NavbarMobile';

export interface NavbarMobileProps extends PaperProps {
  menuController?: UseMUIDialogController['control'];
}

export function NavbarMobile(props: NavbarMobileProps) {
  const navbarMobile = useNavbarMobile();
  const { className, menuController, ...rootProps } = props;
  const user = useAppSelector(AccountContext.getUser);
  const loading = useAppSelector(AccountContext.isLoading);
  const hasUnread = useAppSelector(Notification.hasUnread);

  const isLogin = useMemo(() => !loading && user != null, [loading, user]);

  const entries = useMemo(() => defineMenu(user, { hasUnread, isLogin }), [user, hasUnread, isLogin]);

  return navbarMobile && menuController ? (
    <>
      <NavbarPaper elevation={3} {...rootProps} className={classNames(componentName, className)}>
        <BottomNavigation showLabels={true}>
          {entries.map((entry, index) => {
            return (
              <NavbarMobileTab
                key={index}
                to={entry.to}
                label={entry.label}
                icon={entry.icon}
                feature={entry.feature}
                active={menuController.isOpen ? false : undefined}
                onClick={() => menuController.setOpen(false)}
              />
            );
          })}
          <NavbarMobileTab
            className={classNames(componentName, className)}
            to={undefined}
            label={'Menu'}
            icon={menuController.isOpen ? <Close /> : <MenuIcon />}
            onClick={() => menuController?.setOpen(!menuController.isOpen)}
            active={menuController.isOpen ? true : undefined}
          />
        </BottomNavigation>
      </NavbarPaper>
    </>
  ) : null;
}
