import React from 'react';
import { DefaultLayout } from '../../../components/layouts';
import ChangePasswordUI from './components/ChangePasswordUI';

export const ChangePasswordPage: React.FC = () => {
  return (
    <DefaultLayout mobileBackButton>
      <ChangePasswordUI />
    </DefaultLayout>
  );
};
