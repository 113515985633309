import React from 'react';
import { useFooterLinks } from './useFooterLinks';
import { Link } from 'react-router-dom';
import { Box, type BoxProps, styled, Typography } from '@mui/material';

export interface FooterLinksProps extends BoxProps {}

export const FooterLinks = (props: FooterLinksProps) => {
  const { ...rootProps } = props;
  const links = useFooterLinks();

  return (
    <Box display={'flex'} flexWrap={'wrap'} justifyContent={'center'} {...rootProps}>
      {links.map((link, index) => (
        <>
          <Link key={index} to={link.url}>
            <ButtonLink pl={2}>{link.label}</ButtonLink>
          </Link>

          {index < links.length - 1 ? (
            <Typography fontSize={12} lineHeight={1.3} sx={{ textDecoration: 'none' }}>
              &nbsp;·&nbsp;
            </Typography>
          ) : null}
        </>
      ))}
    </Box>
  );
};

const ButtonLink = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  lineHeight: 1.3,
  padding: 0,
  cursor: 'pointer',
  textDecoration: 'underline',
  color: theme.md3.sys.color.onSurface,
  ':hover': {
    color: theme.md3.sys.color.onSurfaceVariant,
  },
}));
