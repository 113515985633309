import React, { useState, useEffect } from 'react';
import { Navbar, NavbarMobile } from '@/components/Navbar';
import Footer from '../Footer/Footer';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faFile, faTrash } from '@fortawesome/free-solid-svg-icons';
import './DepotDocument.css';
import ReactLoading from 'react-loading';
import moment from 'moment';
import { StatusCodes } from 'http-status-codes';
import { encodeToken, verifyToken } from '../../services/auth';
import { uploadMedicalNotif, verifyAfterVisitInfo } from '../../services/rh';
import { useToast } from '../Toast';

function DepotDocumentVisite() {
  const toast = useToast();
  const [files, setFiles] = useState([]);
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token_decode');
  const [decode, setDecode] = useState({
    idRhNotification: '',
    idEmployeeInformation: '',
    idBusiness: '',
  });
  const [apte, setApte] = useState('1');
  const [loading, setLoading] = useState(false);

  const handleClickCheck = async (event) => {
    setLoading(true);
    event.preventDefault();
    var formData = new FormData();
    formData.set('apt', apte);
    formData.set('dateAction', moment().format('YYYY-MM-DD'));
    for (let file of files) {
      formData.append('documents', file.file);
      formData.append('type_document', file.type);
    }
    encodeToken({
      idRhNotification: decode.idRhNotification,
      idEmployeeInformation: decode.idEmployeeInformation,
      idBusiness: decode.idBusiness,
    }).then((val) => {
      if (val.status === StatusCodes.OK) {
        const token = req.data.token;
        uploadMedicalNotif(token, formData)
          .then((value) => {
            if (value.status === StatusCodes.OK) {
              setLoading(false);
              navigate('/depotdocumentfin');
            }
          })
          .catch((err) => setLoading(false));
      }
    });
  };

  const [access, setAccess] = useState(false);

  useEffect(() => {
    if (token) {
      verifyToken({}, token)
        .then((val) => {
          if (val.status === StatusCodes.OK) {
            setDecode(val.data.decode);
          }
        })
        .catch(() => {
          toast.present({ message: 'Connexion interrompue', severity: 'error' });
        });
    }
  }, []);

  useEffect(() => {
    if (decode.idRhNotification && decode.idEmployeeInformation && decode.idBusiness) {
      verifyAfterVisitInfo(token, {
        idRhNotification: decode.idRhNotification,
        idBusiness: decode.idBusiness,
      }).then((value) => {
        if (value.data.employee) {
          setAccess(true);
        }
      });
    }
  }, [decode]);

  return (
    <div>
      <Navbar />

      {loading ? (
        <div className="container-fluid container-loading">
          <h2>Veuillez patienter</h2>
          <div className="container">
            <ReactLoading type="spin" height={100} width={100} color="rgba(64, 201, 201, 1)"></ReactLoading>
          </div>
        </div>
      ) : (
        <div>
          {access ? (
            <div className="container-fluid">
              <h3 className="question-form mt-5 mb-5 text-center">
                Bonjour, veuillez nous transmettre le/les document(s) transmis par le médecin du travail
              </h3>

              <form className="form-legacy form-declaration col-lg-4">
                <h5 className="question-form mb-3 text-center"> Êtes-vous apte à reprendre votre activité ?</h5>
                <label className="col-12 mb-5">
                  <select
                    className="form-select"
                    name="apte"
                    value={apte}
                    onChange={(e) => {
                      setApte(e.target.value);
                      // if (e.target.value != 3) {
                      //     const index = e.nativeEvent.target.selectedIndex
                      //     setApteLabel(e.nativeEvent.target[index].text)
                      // }
                    }}
                    required
                  >
                    <option value={1}>Je suis apte</option>
                    <option value={2}>Je ne suis pas apte</option>
                  </select>
                </label>
                <h5 className="question-form mb-3 text-center"> Ajouter vos documents juste ici</h5>
                <div className="container-file">
                  <label htmlFor="file" className="label-form-file label-file btn btn-sm col-12">
                    <FontAwesomeIcon icon={faUpload} className="icon-upload"></FontAwesomeIcon> Sélectionner Fichier
                  </label>

                  <input
                    className="form-control form-file input-file"
                    name="document"
                    type="file"
                    id="file"
                    onChange={(event) => {
                      const allowedTypes = event.target.accept.split(', ');
                      let allowedFiles = [...event.target.files]
                        .filter((value) => allowedTypes.includes(value.type))
                        .map((value) => {
                          return {
                            file: value,
                            status: -1,
                          };
                        });
                      setFiles([...files, ...allowedFiles]);
                      setIsActive(false);
                      document.querySelector('#file').value = '';
                    }}
                    accept="image/jpeg, image/png, application/pdf"
                    multiple
                  />
                </div>
                <div className="text-center mb-1 text-muted small">
                  <em>Taille des fichiers : 5 Mo maximum</em>
                </div>
              </form>

              <div
                className={
                  isActive ? 'information-document col-lg-6 row mb-5' : 'information-document col-lg-6 row mb-5 d-none'
                }
              ></div>
              {files.length > 0 && <div>Fichier sélectionné :</div>}
              {files.map((value, index) => {
                return (
                  <div
                    key={index}
                    className="file-container col-lg-4"
                    style={{
                      backgroundColor:
                        (value.status === -1 && 'transparent') ||
                        (value.status === 1 && 'green') ||
                        (value.status === 0 && 'red'),
                    }}
                    onClick={() => {
                      const src = URL.createObjectURL(value.file);
                      window.open(src, '_blank');
                    }}
                  >
                    <FontAwesomeIcon icon={faFile} className="file-icon"></FontAwesomeIcon>
                    <div className="file-name">{value.file.name}</div>
                    <FontAwesomeIcon
                      icon={faTrash}
                      className="close-icon"
                      onClick={(e) => {
                        e.stopPropagation();
                        const newFiles = files.filter((v, i) => i !== index);
                        setFiles(newFiles);
                      }}
                    ></FontAwesomeIcon>
                  </div>
                );
              })}

              <div className="position-relative col-12 col-md-8 text-center mb-3">
                <button
                  type="button"
                  className={
                    isActive || files.length > 0
                      ? 'btn btn-kerij col-6 col-lg-3'
                      : 'btn btn-kerij col-6 col-lg-3 disabled'
                  }
                  onClick={handleClickCheck}
                >
                  Transmettre les documents
                </button>
              </div>
            </div>
          ) : (
            <div className="container-fluid mt-5">
              <h1 className="question-form mb-5">Attention ! Vous n'avez pas l'autorisation d'accéder à cette page</h1>
            </div>
          )}
        </div>
      )}
      <NavbarMobile />
      <Footer />
    </div>
  );
}

export default DepotDocumentVisite;
