import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchEmployeeProfile } from '../../../services/employee';
import isAuthorized from '../../../hooks/isAuthorized';
import { AccountContext, useAppDispatch, useAppSelector } from '@/store';
import { AxiosResponse } from 'axios';
import { useToast } from '@/components/Toast';
import { Box, Divider, Typography } from '@mui/material';
import { Spacer } from '@/components/Spacer';
import { ProfilUIResume } from './ProfilUIResume';
import { ProfilUIMobileContent } from './ProfilUIMobileContent';
import { ProfilUIDesktopContent } from './ProfilUIDesktopContent';
import { FooterLinks } from '@/components/Footer';
import { useAppVariant } from '@/hooks/useAppVariant';

export function ProfilUI() {
  const accountContext = useAppSelector((state) => state.accountContext);

  const { pathname } = useLocation();
  const appVariant = useAppVariant();
  const toast = useToast();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onLogout = (message: string, severity: 'success' | 'error' = 'error') => {
    dispatch(AccountContext.logout());
    toast.present({ message, severity });
    return navigate('/login', { state: { fromPath: '/profil' } });
  };

  async function findEmployee() {
    let profilValue: AxiosResponse;

    try {
      profilValue = await fetchEmployeeProfile();
      if (profilValue?.data?.last_change_password) return dispatch(AccountContext.actions.setUser(profilValue.data));
      else onLogout('Veuillez vous reconnectez');
    } catch {
      onLogout('Erreur de connexion au serveur');
    }
  }

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    if (isAuthorized(pathname)) findEmployee();
    else navigate('/login', { state: { fromPath: '/profil' } });
  }, []);

  return (
    <Box display={'flex'} justifyContent={'space-around'}>
      <Box maxWidth={1080} width={'100%'}>
        <Box pl={appVariant.small ? 2 : 4} pr={appVariant.small ? 2 : 4}>
          <Spacer spacing={appVariant.small ? 8 : 1} />

          <Typography fontWeight={600} fontSize={appVariant.small ? 32 : 24} lineHeight={'normal'}>
            Profil
          </Typography>

          <Spacer spacing={4} />
          <ProfilUIResume user={accountContext.user} />

          <Spacer spacing={appVariant.small ? 6 : 4} />
          <Divider />
          <Spacer spacing={appVariant.small ? 6 : 3.5} />
        </Box>

        {appVariant.small ? <ProfilUIDesktopContent /> : <ProfilUIMobileContent />}

        <Box position={'absolute'} width={'100%'} sx={(theme) => ({ bottom: theme.spacing(12) })}>
          {!appVariant.small && <FooterLinks />}
        </Box>
      </Box>
    </Box>
  );
}
