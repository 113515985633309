import React from 'react';
import { AppTextField } from '@/components/AppTextField';

export interface EmailInputProps {
  register: any;
  label: string;
  error?: any;
  dense?: boolean;
}

const InputEmailValidate = ({ dense, register, label, error }: EmailInputProps) => {
  return (
    <AppTextField
      dense={dense}
      label={label}
      error={error}
      type="email"
      InputProps={{
        inputProps: {
          ...register('email', {
            required: 'Email requis',
            pattern: {
              value: /^[\w%+.-]+@[\d.a-z-]+\.[a-z]{2,4}$/i,
              message: `Format d'email invalide`,
            },
          }),
        },
      }}
      noHelper
    />
  );
};

export default InputEmailValidate;
