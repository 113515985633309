import { DefaultLayout } from '../../../components/layouts';
import { useLocation, useNavigate } from 'react-router-dom';
import { AbsenceDate } from '../../../features/declaration/components/date/AbsenceDate';
import { HospiDate } from '../../../features/declaration/components/date/HospiDate.component';
import { MotherDate } from '../../../features/declaration/components/date/MotherDate.component';
import { FatherDate } from '../../../features/declaration/components/date/FatherDate.component';
import { StepProgressBar } from '../../../features/declaration/components/bars/StepProgressBar';
import { NavigationContext } from '../../../components/Context/NavigationContext';
import React, { useContext, useEffect } from 'react';
import { NavigationSteps } from '../../../components/Context/NavigationProvider';
import { Box, Typography } from '@mui/material';
import { Spacer } from '@/components/Spacer';
import { useAppVariant } from '@/hooks/useAppVariant';

const getAbsenceDate = (absenceType: string) => {
  switch (absenceType) {
    case '1': {
      return <AbsenceDate dense />;
    }
    case '2': {
      return <HospiDate dense />;
    }
    case '3': {
      return <MotherDate />;
    }
    case '4': {
      return <FatherDate />;
    }
    default: {
      return <></>;
    }
  }
};

export const DeclarationDateUI = () => {
  const appVariant = useAppVariant();
  const location = useLocation();
  const navigate = useNavigate();

  const document = location.state?.document;
  const absenceType = document?.absenceType?.value;
  const { setCurrentStep } = useContext(NavigationContext);

  useEffect(() => {
    if (document == null) navigate('/declaration');
  }, [document]);

  useEffect(() => {
    setCurrentStep(NavigationSteps.DECLARATION_DATE);
  }, []);

  return (
    <Box display={'flex'} justifyContent={'space-around'}>
      <Box maxWidth={1080} width={'100%'}>
        <Box pl={appVariant.small ? 2 : 4} pr={appVariant.small ? 2 : 4}>
          <Spacer spacing={appVariant.medium ? 8 : 2} />
          <StepProgressBar type={absenceType} />
          <Spacer spacing={4} />

          {getAbsenceDate(absenceType)}
        </Box>
        <Spacer spacing={3} />
      </Box>
    </Box>
  );
};
