import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { employeeSendCodeNoAuth, employeeVerifyContact } from '../../../../../services/employee';
import { AppTextField } from '@/components/AppTextField';
import { ActionButton } from '@/components/ActionButton';
import { Spacer } from '@/components/Spacer';

const ModifyContactUI = () => {
  const [tel, setTel] = useState('');
  const [mail, setMail] = useState('');
  const [validContact, setValidContact] = useState<boolean>(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const state = useLocation();
  useEffect(() => {
    if (!state.state) {
      navigate('/login', { state: { fromPath: '/profil' } });
    }
    if (mail || tel) {
      employeeVerifyContact({
        phone: tel,
        email: mail,
        idEmployeeLogin: state.state.data.id,
      }).then((value) => {
        if (value.data.hasExisted) {
          setMessage('Adresse email ou téléphone sont déjà utilisés');
          setValidContact(false);
        } else {
          setMessage('');
          setValidContact(true);
        }
      });
    } else if (!mail && !tel) {
      setMessage('');
      setValidContact(false);
    }
  }, [mail, tel]);

  const handleSubmit = async (event: any) => {
    try {
      event.preventDefault();
      if (validContact) {
        employeeSendCodeNoAuth({
          idEmployeeLogin: state.state.data.id,
          email: state.state.data.email,
          idEmployeeInformation: state.state.data.employee_information.id,
          phone: state.state.data.phone,
        })
          .then((value) => {
            navigate('/confirmation', {
              state: {
                id: state.state.data.id,
                mail: mail,
                telephone: tel,
                nom: state.state.data.employee_information.lastname,
                page: state.state.page,
                document: state.state.document,
                employee_information: {
                  business: {
                    id: state.state.data.employee_information.business.id,
                  },
                },
              },
            });
          })
          .catch((err) => {
            navigate('/confirmation', {
              state: {
                id: state.state.data.id,
                mail: mail,
                telephone: tel,
                nom: state.state.data.employee_information.lastname,
                page: state.state.page,
                document: state.state.document,
                employee_information: {
                  business: {
                    id: state.state.data.employee_information.business.id,
                  },
                },
              },
            });
          });
      }
    } catch (error) {
      alert(error);
    }
  };

  return (
    <div>
      <div className="container-fluid">
        <Spacer size="large" />

        <form onSubmit={handleSubmit} style={{ padding: 0, width: 350, textAlign: 'center' }}>
          <h3 className="question-form mb-5">Informations de contact</h3>

          <div className="msg-pass">
            <em>
              {`Ces informations permettent à KERij de vous
                            accompagner tout au long de vos parcours d'absences.
                            Elles ne seront, en aucun cas, transmises à votre
                            employeur.`}
            </em>
          </div>

          <AppTextField
            label={'Téléphone'}
            value={tel}
            onChange={(_, value) => setTel(value)}
            InputProps={{ inputProps: { type: 'tel' } }}
          />

          <AppTextField
            label={'E-mail'}
            value={mail}
            onChange={(_, value) => setMail(value)}
            InputProps={{ inputProps: { type: 'email' } }}
          />

          <div className="err">{message}</div>

          <Spacer />

          <ActionButton color="primary" type="submit" actionName="validate" fullWidth startIcon={null} />
        </form>
      </div>
    </div>
  );
};

export default ModifyContactUI;
