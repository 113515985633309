import React from 'react';
import { Box, BoxProps, styled } from '@mui/material';
import { FooterLinks } from './FooterLinks';

export interface FooterProps extends BoxProps {}

export const Footer = (props: FooterProps) => {
  const { ...rootProps } = props;

  return (
    <FooterStyled {...rootProps}>
      <FooterLinks />
    </FooterStyled>
  );
};

const FooterStyled = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(5),
  background: '#fff',
}));

export default Footer;
