import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Navbar, NavbarMobile } from '@/components/Navbar';
import Footer from '../../../components/Footer/Footer';
import { useAppSelector } from '@/store';

export const DeclarationQuiPage = () => {
  const context: any = useAppSelector((state) => state.accountContext);
  const [absenceQui, setAbsenceQui] = useState('1');
  const [absenceQuiLabel, setAbsenceQuiLabel] = useState('Je déclare pour moi-même');
  const navigate = useNavigate();
  const state = useLocation();

  useEffect(() => {
    if (context.user) {
      if (state.state.document.absenceType.value === '1') {
        navigate('/declarationprolongation', {
          state: {
            document: {
              ...state.state.document,
              absenceQui: {
                value: absenceQui,
                label: absenceQuiLabel,
              },
            },
          },
        });
      } else {
        navigate('/declarationdetailstype1', {
          state: {
            document: {
              ...state.state.document,
              absenceQui: {
                value: absenceQui,
                label: absenceQuiLabel,
              },
            },
          },
        });
      }
    } else {
      navigate('/login', {
        replace: true,
        state: {
          fromPath: '/declaration',
          document: {
            ...state.state.document,
            absenceQui: {
              value: absenceQui,
              label: absenceQuiLabel,
            },
          },
        },
      });
    }
  }, []);

  return (
    <div>
      <Navbar />
      <div className="container-fluid">
        <div className="progresses mt-3">
          <div className="steps-active">
            <span>1</span>
          </div>
          <div className="steps">
            <span>.</span>
          </div>
          <div className="steps">
            <span>.</span>
          </div>
          <div className="steps">
            <span>.</span>
          </div>
        </div>

        <h3 className="question-form mt-5 mb-5 text-center">
          Voulez vous déclarer une absence pour vous ou pour un tiers ?
        </h3>

        <form className="form-legacy form-declaration col-lg-4">
          <label>
            <select
              className="form-select"
              name="absence"
              value={absenceQui}
              onChange={(e: any) => {
                setAbsenceQui(e.target.value);
                const index = e.nativeEvent.target.selectedIndex;
                setAbsenceQuiLabel(e.nativeEvent.target[index].text);
              }}
              required
            >
              <option value={1}>Je déclare pour moi-même</option>
              <option value={2}>Je déclare pour un tiers</option>
            </select>
          </label>
        </form>

        <div className="position-relative col-12 col-md-8 text-center mb-3">
          <Link
            className="btn-link"
            to="/declarationdocument"
            state={{
              document: {
                ...state.state.document,
                absenceQui: {
                  value: absenceQui,
                  label: absenceQuiLabel,
                },
              },
            }}
          >
            <button type="button" className="btn btn-kerij col-6 col-lg-3">
              Suivant
            </button>
          </Link>
        </div>

        <div className="position-relative col-12 col-md-8 text-center mb-5">
          <button
            type="button"
            className="btn btn-kerij-outline btn-sm col-6 col-lg-3"
            onClick={() => navigate('-1', { state: state.state })}
          >
            Précédent
          </button>
        </div>
      </div>
      <NavbarMobile />
      <Footer />
    </div>
  );
};
