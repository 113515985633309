import React from 'react';
import classNames from 'clsx';
import { Box, BoxProps, Typography } from '@mui/material';
import { Absence, AbsenceStatus } from '@/types';
import moment from 'moment';

const componentName = 'EmployeeAbsenceCardState';

export interface EmployeeAbsenceCardStateProps extends Omit<BoxProps, 'children'> {
  value: Partial<Pick<Absence, 'date_end' | 'date_end_hospi_theory' | 'status'>>;
}

export function EmployeeAbsenceCardState(props: EmployeeAbsenceCardStateProps) {
  const { value, className, ...boxProps } = props;

  const today = moment().startOf('day');
  const absenceEnd = moment(value.date_end ?? value.date_end_hospi_theory).startOf('day');
  const isEnded = today.isSameOrAfter(absenceEnd);
  const isDeleted = value.status === AbsenceStatus.DELETED;

  return (
    <Box className={classNames(componentName, className)} display={'flex'} flexDirection={'column'} {...boxProps}>
      <Typography lineHeight={1} margin={'auto'} fontSize={12}>
        {isDeleted ? 'Absence supprimée' : isEnded ? 'Absence terminée' : 'Absence en cours'}
      </Typography>
    </Box>
  );
}
