import React from 'react';
import classNames from 'clsx';
import { Navbar, NavbarMobile, NavbarMobileMenu, useNavbarMobile } from '@/components/Navbar';
import Footer from '@/components/Footer/Footer';
import { Box, type BoxProps } from '@mui/material';
import { useAppVariant } from '@/hooks/useAppVariant';
import { DefaultLayoutMobileBack } from './DefaultLayoutMobileBack';
import { useMUIDialogController } from '@/hooks/useMUIDialogController';
import './DefaultLayout.scss';

const componentName = 'DefaultLayout';

export interface DefaultLayoutProps extends BoxProps {
  /**
   * ### Permet d'afficher un bouton de retour sur mobile
   * default: false
   * - true : navigue vers la page précédente.
   * - 'open-menu' : ouvre le menu dans la page courante
   */
  mobileBackButton?: 'open-menu' | true;
  /**
   * ### Affiche la navbar mobile et desktop
   * default: true
   */
  navbar?: boolean;
  /**
   * ### Affiche le footer sur mobile
   * default: false
   * - Le footer est toujours disponible sur Desktop
   */
  mobileFooter?: boolean;
}

export const DefaultLayout = (props: DefaultLayoutProps) => {
  const appVariant = useAppVariant();
  const navbarMobile = useNavbarMobile();

  const { mobileBackButton, navbar = true, mobileFooter, className, children, ...otherProps } = props;

  const modal = useMUIDialogController();

  const onBackCallback = () => {
    if (mobileBackButton === 'open-menu') return () => modal.control.setOpen(true);
  };

  return (
    <Box minHeight={'100vh'} {...otherProps} className={classNames(componentName, className)}>
      {navbar && (
        <Box className={`${componentName}__Header`} component={'header'}>
          <Navbar />
        </Box>
      )}

      <Box
        component={'main'}
        className={`${componentName}__Content`}
        sx={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          pt: navbarMobile || !navbar ? 0 : 7,
        }}
      >
        <Box
          className={`${componentName}__Container`}
          style={{
            paddingBottom: appVariant.small ? 63 : !navbar && !mobileFooter ? 0 : 56, // NavbarMobile
          }}
        >
          {mobileBackButton && !appVariant.medium && <DefaultLayoutMobileBack onBack={onBackCallback()} />}

          {children}
        </Box>
      </Box>

      <Box component={'footer'} className={`${componentName}__Footer`} sx={{ zIndex: 1000 }}>
        {navbar && <NavbarMobile menuController={modal.control} />}
        {navbar && <NavbarMobileMenu {...modal.getRootProps()} />}
        {!navbarMobile || mobileFooter ? <Footer /> : null}
      </Box>
    </Box>
  );
};
