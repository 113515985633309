import React from 'react';
import { DefaultLayout } from '@/components/layouts';
import { ReportUI } from './ReportUI';

export const ReportPage: React.FC = () => {
  return (
    <DefaultLayout mobileBackButton="open-menu">
      <ReportUI />
    </DefaultLayout>
  );
};
