import React from 'react';
import { Link } from 'react-router-dom';
import { IfFeature } from '@/components/IfFeature';
import { BrandIcon } from '@/components/BrandIcon';
import { NavbarMenuButton } from './NavbarMenuButton';
import {
  AppBar as AppBarDefault,
  type AppBarProps,
  Box,
  Stack,
  styled,
  Toolbar,
  typographyClasses,
} from '@mui/material';
import { Button } from '@/components/Button';
import classNames from 'clsx';
import { useNavbarMobile } from './useNavbarMobile';
import { useAppVariant } from '@/hooks/useAppVariant';

const componentName = 'Navbar';

export interface NavbarProps extends AppBarProps {
  isLogin?: boolean;
}

export function Navbar(props: NavbarProps) {
  const navbarMobile = useNavbarMobile();
  const appVariant = useAppVariant();
  const { isLogin = true, className, ...otherProps } = props;
  // const user = useAppSelector(AccountContext.getUser);
  // const loading = useAppSelector(AccountContext.isLoading);

  // const isLogin = useMemo(() => !loading && user != null, [loading, user]);

  const renderNavButton = (label: React.ReactNode, to: string) => (
    <Button
      component={Link}
      className={`${componentName}__NavButton`}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      to={to}
    >
      {label}
    </Button>
  );

  return navbarMobile ? null : (
    <AppBar
      className={classNames(
        componentName,
        `${componentName}--variant-${appVariant.large ? 'lg' : appVariant.medium ? 'md' : appVariant.small ? 'sm' : 'xs'}`,
        className,
      )}
      position="static"
      color="default"
      {...otherProps}
    >
      <Toolbar className={`${componentName}__Toolbar`}>
        <Link
          className={`${componentName}__Brand`}
          to="/"
          style={{ color: 'inherit', textDecoration: 'inherit', display: 'block' }}
        >
          <BrandIcon className={`${componentName}__BrandIcon`} />
        </Link>
        <Box className={`${componentName}__Spacer`} sx={{ flexGrow: 1 }} />
        {isLogin && (
          <Stack direction={'row'} alignItems={'center'}>
            {renderNavButton('Déclarer une absence', '/declaration')}
            <IfFeature name="ServiceManagement">{renderNavButton('Services', '/services')}</IfFeature>
            <IfFeature name="InterviewManagement">{renderNavButton('Rendez-vous', '/rdv')}</IfFeature>
            {renderNavButton('Activités', '/activites')}
          </Stack>
        )}
        <NavbarMenuButton isLogin={isLogin} className={`${componentName}__MenuButton`} />
      </Toolbar>
    </AppBar>
  );
}

export const AppBar = styled(AppBarDefault, {
  name: componentName,
})(({ theme }) => ({
  backgroundColor: theme.md3.sys.color.white,
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  [`& .${componentName}__BrandIcon`]: {
    height: theme.spacing(6),
  },
  [`& .${componentName}__NavButton .${typographyClasses.root}`]: {
    ...theme.md3.sys.typescale.body.medium,
  },
  [`& .${componentName}__NavButton .${typographyClasses.root}`]: {
    ...theme.md3.sys.typescale.body.large,
  },
  [`& .${componentName}__MenuButton`]: {
    marginLeft: theme.spacing(3),
  },
  [`&.${componentName}--variant-lg .${componentName}__Toolbar`]: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
  },
}));
