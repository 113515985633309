import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import validator from 'validator';
import { useDebouncedCallback } from 'use-debounce';
import { Spacer } from '@/components/Spacer';
import { AppTextField } from '@/components/AppTextField';
import { ActionButton } from '@/components/ActionButton';
import { Box, styled, Typography } from '@mui/material';
import { EmployeeSectionCard } from '@/components/EmployeeSectionCard';
import { useToast } from '@/components/Toast';
import { useAppVariant } from '@/hooks/useAppVariant';

function ForgetPasswordUI() {
  const appVariant = useAppVariant();
  const toast = useToast();
  const [login, setLogin] = useState('');
  const [telephone, setTelephone] = useState('');
  const message: any = '';
  const user: any = null;
  const navigate = useNavigate();
  const state = useLocation();

  const [emailError, setEmailError] = useState<any>(null);
  const [phoneError, setPhoneError] = useState<any>(null);
  const [submitting, setSubmitting] = useState(false);

  const validateEmail = useDebouncedCallback((email) => {
    if (validator.isEmail(email)) {
      setEmailError(null);
    } else {
      const message = 'Veuillez entrer une adresse email valide';
      toast.present({ message, severity: 'error' });
      setEmailError(message);
    }
  }, 1000);

  const validatePhone = useDebouncedCallback((phone) => {
    if (validator.isMobilePhone(phone, 'fr-FR')) {
      setPhoneError(null);
    } else {
      const message = 'Veuillez entrer un numéro de téléphone valide';
      toast.present({ message, severity: 'error' });
      setPhoneError(message);
    }
  }, 500);

  const handleSubmit = (event: any) => {
    setSubmitting(true);
    try {
      event.preventDefault();
      if (!login && !telephone) {
        toast.present({ severity: 'error', message: "Le téléphone et l'e-mail sont obligatoires" });
        setSubmitting(false);
      }
      if (message === `Cet identifiant n'existe pas`) {
        toast.present({ severity: 'error', message: 'Cet identifiant ne semble pas exister' });
        setSubmitting(false);
      } else if ((login || telephone) && message !== "Cet identifiant n'existe pas") {
        setSubmitting(false);
        navigate('/resetPassword', {
          state: {
            id_salarie_login: user ? user.id : '',
            mail: login,
            telephone: telephone,
            nom: user ? user.employee_information.lastname : '',
            prenom: user ? user.employee_information.firstname : '',
            document: state.state.document,
            idBusiness: user ? user.employee_information.business.id : '',
            idEmployeeInformation: user ? user.employee_information.id : '',
          },
        });
      }
    } catch {
      toast.present({ severity: 'error', message: 'Une erreur est survenue' });
      setSubmitting(false);
    }
  };

  return (
    <Box pl={4} pr={4}>
      <Spacer spacing={appVariant.small ? 24 : 15} />

      <Typography fontWeight={600} fontSize={20} textAlign={'center'} lineHeight={'normal'}>
        Modifiez votre mot de passe
      </Typography>

      <Spacer spacing={3} />

      <form onSubmit={handleSubmit}>
        <Box>
          <EmployeeSectionCard sx={(theme) => ({ maxWidth: appVariant.small ? 336 : undefined, margin: 'auto' })}>
            <AppTextField
              dense
              label={'E-mail'}
              error={message != null && message.trim() != ''}
              InputProps={{ type: 'email' }}
              onChange={(_, value) => {
                setLogin(value);
                validateEmail(value);
              }}
              noHelper
            />
            <Spacer spacing={3} />

            <AppTextField
              dense
              label={'Téléphone'}
              error={message != null && message.trim() != ''}
              InputProps={{ type: 'tel' }}
              onChange={(_, value) => {
                setTelephone(value);
                validatePhone(value);
              }}
              noHelper
            />

            <Spacer spacing={3} />

            <ActionButton
              type="submit"
              color="primary"
              variant="contained"
              actionName="validate"
              label={'Valider'}
              startIcon={null}
              fullWidth
              submitting={submitting}
            />
          </EmployeeSectionCard>
          <Spacer spacing={4} />

          <Box width={'100%'} display={'flex'} justifyContent={'center'}>
            <ButtonLink fontSize={14} pl={0.5} color="primary" onClick={() => navigate('/login', { replace: true })}>
              Se connecter
            </ButtonLink>
          </Box>
        </Box>
      </form>

      <Spacer spacing={1} />
    </Box>
  );
}

export default ForgetPasswordUI;

const ButtonLink = styled(Typography)(({ theme, color }) => ({
  fontWeight: 400,
  lineHeight: 'normal',
  cursor: 'pointer',
  color: color ? undefined : theme.md3.sys.color.onSurfaceVariant,
  textDecoration: 'underline',
  ':hover': {
    color: theme.md3.sys.color.onSurface,
  },
}));
