import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ParcoursPublicQuery } from '@/query';
import { RdvUIModal } from './RdvUIModal';
import { useMUIDialogController } from '@/hooks/useMUIDialogController';
import { ParcoursPublic } from '@/types';
import { Spacer } from '@/components/Spacer';
import { Box, Grid, Typography } from '@mui/material';
import { useToast } from '@/components/Toast';
import { StatusCodes } from 'http-status-codes';
import { RdvUIItem } from './RdvUIItem';
import { useAppVariant } from '@/hooks/useAppVariant';

export function RdvUI() {
  const appVariant = useAppVariant();
  const navigate = useNavigate();
  const toast = useToast();

  const query = ParcoursPublicQuery.useIndex();
  const parcoursPublicTriggerMutation = ParcoursPublicQuery.useTrigger();
  const confirmDialogController = useMUIDialogController();

  const [submitting, setSubmitting] = useState(false);
  const [parcours, setParcours] = useState<ParcoursPublic>();

  async function triggerParcours() {
    setSubmitting(true);
    if (parcours?.parcours_id)
      try {
        const { status } = await parcoursPublicTriggerMutation.mutateAsync({ parcours_id: parcours.parcours_id });

        if (status === StatusCodes.OK)
          toast.present({ message: 'Votre demande a déjà été prise en compte pour ce rendez-vous', severity: 'error' });
        else if (status === StatusCodes.CREATED)
          toast.present({ message: 'Votre demande a bien été prise en compte', severity: 'success' });
        else toast.present({ message: 'Une erreur est survenue, veuillez nous excusez', severity: 'error' });
      } catch {
        toast.present({ message: 'Une erreur est survenue, veuillez nous excusez', severity: 'error' });
      }
    confirmDialogController.control.setOpen(false);
    setSubmitting(false);
  }

  useEffect(() => {
    if (localStorage.getItem('access_token_employee')) query.refetch();
    else navigate('/login', { replace: true, state: { fromPath: '/rdv' } });
  }, []);

  return (
    <Box display={'flex'} justifyContent={'space-around'}>
      <Box maxWidth={1080} width={'100%'}>
        <Box pl={appVariant.small ? 2 : 4} pr={appVariant.small ? 2 : 4}>
          <Spacer spacing={appVariant.small ? 8 : 4} />

          <Typography fontWeight={600} fontSize={appVariant.small ? 32 : 24} lineHeight={'normal'}>
            Solliciter un rendez-vous
          </Typography>

          <Spacer spacing={2} />

          <Typography fontSize={14} lineHeight={1.4} sx={(theme) => ({ color: theme.md3.sys.color.onSurfaceVariant })}>
            Différentes circonstances personnelles et/ou professionnelles peuvent vous conduire à solliciter un RDV avec
            un interlocuteur interne ou externe à l’entreprise en vue d’évoquer et de gérer cette situation.
          </Typography>

          <Spacer spacing={4} />

          <Grid container spacing={2} mb={3}>
            {query.data?.map((value, index) => (
              <Grid key={index} item xs={12} md={3} sx={{ display: 'flex', flexDirection: 'column', height: 'auto' }}>
                <RdvUIItem
                  value={value}
                  onTrigger={() => {
                    setParcours(value);
                    confirmDialogController.control.setOpen(true);
                  }}
                />
              </Grid>
            ))}
          </Grid>

          <RdvUIModal
            value={parcours}
            onSubmit={triggerParcours}
            {...confirmDialogController.getRootProps()}
            disabled={submitting}
          />
        </Box>
      </Box>
    </Box>
  );
}
