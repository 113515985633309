import React from 'react';
import { Absence, DocumentAbsence, DocumentStatus } from '@/types';
import { EmployeeAbsenceCard } from '@/components/EmployeeAbsenceCard';
import { DossierUIContentProps } from './DossierUIContent';

const componentName = 'DossierUIList';

export interface DossierUIListProps {
  absences: Array<Absence>;
  type: keyof DossierUIContentProps['value'];
}

export const DossierUIList = (props: DossierUIListProps) => {
  const { absences, type } = props;

  const cardColor = (absenceDocument?: DocumentAbsence) => {
    if (type === 'pending') return absenceDocument ? 'success' : 'warning';
    if (type === 'finished') return 'variant';
    if (type === 'deleted') return 'disabled';
  };

  return (
    <>
      {absences.map((absence) => {
        const absenceDocument = findDocument(absence);

        return (
          <EmployeeAbsenceCard
            className={componentName}
            absenceDocument={absenceDocument}
            color={cardColor(absenceDocument)}
            key={absence.id}
            absence={absence}
          />
        );
      })}
    </>
  );
};

const findDocument = (absence: Absence) => {
  const validDocument = absence.documents?.find((doc) => doc?.status === DocumentStatus.APPROVED);
  const pendingDocument = absence.documents?.find((doc) => doc?.status === DocumentStatus.PENDING);
  return validDocument ?? pendingDocument;
};
