import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, BoxProps, CardActionArea, Grid, Typography } from '@mui/material';
import { Spacer } from '@/components/Spacer';
import { CreateRounded, FeaturedPlayListRounded } from '@mui/icons-material';
import { AccountCircleOutlined } from '@/components/AppIcon';
import { EmployeeSectionCard } from '@/components/EmployeeSectionCard';

export interface ProfilUIDesktopContentProps extends BoxProps {}

export function ProfilUIDesktopContent(props: ProfilUIDesktopContentProps) {
  const { ...boxProps } = props;
  const navigate = useNavigate();

  const onChange = (to: string) => {
    navigate(to, {
      state: { data: to, page: 'Login', fromPath: '/profil' },
    });
  };

  const renderButton = (type: 'info' | 'password' | 'dossier') => {
    const routePath = type === 'info' ? '/profil/edit' : type === 'password' ? '/changepassword' : '/dossier';
    const icon =
      type === 'info' ? (
        <AccountCircleOutlined />
      ) : type === 'password' ? (
        <CreateRounded />
      ) : (
        <FeaturedPlayListRounded />
      );
    const title =
      type === 'info' ? 'Informations personnelles' : type === 'password' ? 'Changer de mot de passe' : 'Mon dossier';
    const helperText =
      type === 'info'
        ? 'Fournissez des renseignements personnels et indiquez comment nous pouvons vous joindre'
        : type === 'password'
          ? 'Mettre à jour votre mot de passe'
          : "Retrouvez l'historique de vos absences";

    return (
      <CardActionArea
        sx={(theme) => ({ borderRadius: theme.spacing(1.25), height: '100%' })}
        onClick={() => onChange(routePath)}
      >
        <EmployeeSectionCard padding={2} sx={{ height: '100%' }}>
          <Typography fontSize={32} lineHeight={0.5}>
            {icon}
          </Typography>
          <Spacer spacing={4} />
          <Typography fontSize={16} fontWeight={600} lineHeight={1.25}>
            {title}
          </Typography>
          <Spacer spacing={0.5} />
          <Typography fontSize={14} lineHeight={1.4}>
            {helperText}
          </Typography>
        </EmployeeSectionCard>
      </CardActionArea>
    );
  };

  return (
    <Box pl={2} pr={2} {...boxProps}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={4}>
          {renderButton('info')}
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          {renderButton('password')}
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          {renderButton('dossier')}
        </Grid>
      </Grid>
    </Box>
  );
}
