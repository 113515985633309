import React from 'react';
import { DefaultLayout } from '@/components/layouts';
import { DeclarationProlongationUI } from './DeclarationProlongationUI';

export const DeclarationProlongationPage: React.FC = () => {
  return (
    <DefaultLayout>
      <DeclarationProlongationUI />
    </DefaultLayout>
  );
};
