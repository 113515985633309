import React from 'react';
import { DefaultLayout } from '@/components/layouts';
import { DeclarationDateUI } from './DeclarationDateUI';

export const DeclarationDatePage: React.FC = () => {
  return (
    <DefaultLayout>
      <DeclarationDateUI />
    </DefaultLayout>
  );
};
