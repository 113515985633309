import React from 'react';
import { DefaultLayout } from '@/components/layouts';
import { ProfilUI } from './ProfilUI';

export const ProfilPage: React.FC = () => {
  return (
    <DefaultLayout mobileBackButton="open-menu">
      <ProfilUI />
    </DefaultLayout>
  );
};
