import { absenceFindAll } from '@/services/employee/absence';
import { useQuery } from 'react-query';

export const AbsenceQuery = (() => {
  const useIndex = (parameters?: absenceFindAll.Parameters) =>
    useQuery(
      ['absenceIndex', parameters],
      async () => {
        return absenceFindAll(parameters);
      },
      { keepPreviousData: true },
    );

  return {
    useIndex,
  };
})();
