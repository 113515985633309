import React, { useEffect } from 'react';
import { DefaultLayout } from '../../../components/layouts';

import { useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useError } from '@/shared/useError';
import { NavigationContext } from '../../../components/Context/NavigationContext';
import { StepProgressBar } from '../../../features/declaration/components/bars/StepProgressBar';
import { NavigationSteps } from '../../../components/Context/NavigationProvider';
import { Box, Tooltip, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { colors } from '../../../constants/theme.constants';
import { AccountContext, useAppSelector } from '@/store';
import { Button } from '@/components/Button';
import { Spacer } from '@/components/Spacer';
import { ActionButton } from '@/components/ActionButton';
import { AppTextField } from '@/components/AppTextField';
import { QuestionYesNoInput } from '@/components/QuestionYesNoInput';
import { useAppVariant } from '@/hooks/useAppVariant';

export const DeclarationMessageUI = () => {
  const appVariant = useAppVariant();
  const location = useLocation();
  const navigate = useNavigate();
  const user = useAppSelector(AccountContext.getUser);

  const document = location.state.document ?? {};
  const absenceType = document.absenceType?.value;
  const [isTextArea, setIsTextArea] = useState<boolean>(document.textArea || false);
  const [message, setMessage] = useState<string>(document.message || '');
  const { setCurrentStep } = useContext(NavigationContext);
  const { error, triggerError, clearError } = useError();

  const handleClickNext = async () => {
    if (user) {
      navigate('/declaration-validation', {
        state: {
          ...location.state,
          document: {
            ...document,
            message: message,
            textArea: isTextArea,
          },
        },
      });
    } else {
      navigate('/login', { state: { ...location.state, fromPath: '/declaration' }, replace: true });
    }
  };

  const handleClickPrev = () => {
    navigate('/declaration-questions-contact', {
      state: location.state,
    });
  };

  useEffect(() => {
    setCurrentStep(NavigationSteps.DECLARATION_QUESTIONS_MESSAGE);
  }, []);

  return (
    <Box display={'flex'} justifyContent={'space-around'}>
      <Box maxWidth={1080} width={'100%'}>
        <Box pl={appVariant.small ? 2 : 4} pr={appVariant.small ? 2 : 4}>
          <Spacer spacing={appVariant.medium ? 8 : 2} />
          <StepProgressBar type={absenceType} />
          <Spacer spacing={4} />

          <Typography textAlign={'center'} fontWeight={600} fontSize={appVariant.small ? 32 : 24} lineHeight={'normal'}>
            {'Je souhaite adresser un message personnalisé à mon manager'}
          </Typography>

          <Spacer spacing={4} />

          <Box display={'flex'} justifyContent={'center'}>
            <form onSubmit={handleClickNext} style={{ maxWidth: 620, width: '100%' }}>
              <Box textAlign={'center'}>
                <QuestionYesNoInput value={isTextArea} onChange={(_, value) => setIsTextArea(value)} />
              </Box>

              {isTextArea ? (
                <div>
                  <p
                    style={{
                      fontStyle: 'italic',
                      fontWeight: '900',
                      // color: 'red',
                    }}
                  >
                    Dans la rédaction du présent message, vous pouvez renseigner des informations librement.
                    <span
                      style={{
                        marginLeft: '5px',
                      }}
                    >
                      <Tooltip title="Nous vous invitons à ne mentionner aucune information relative à votre éventuelle pathologie et situation médicale. Conformément à la réglementation, vous devez par ailleurs veiller impérativement à ne pas faire de commentaires excessifs ou insultants envers les individus.">
                        <InfoIcon
                          style={{
                            color: colors.primary,
                          }}
                        />
                      </Tooltip>
                    </span>
                  </p>

                  <AppTextField
                    value={message}
                    placeholder="Votre message ..."
                    onChange={(_, value) => setMessage(value)}
                    InputProps={{
                      multiline: true,
                      rows: 10,
                      maxRows: 20,
                    }}
                  />
                </div>
              ) : (
                <></>
              )}

              <Spacer spacing={3} />
              <Box display={'flex'} justifyContent={'center'}>
                <Box onSubmit={handleClickNext} style={{ maxWidth: 420, width: '100%' }}>
                  <ActionButton
                    fullWidth
                    startIcon={null}
                    type="submit"
                    variant="contained"
                    actionName="next"
                    disabled={error != null}
                    onClick={handleClickNext}
                    style={{ width: '100%' }}
                  />
                  <Spacer />
                  <Button fullWidth color="secondary" onClick={handleClickPrev} style={{ width: '100%' }}>
                    Précédent
                  </Button>
                </Box>
              </Box>
            </form>
          </Box>

          <Spacer spacing={3} />
        </Box>
      </Box>
    </Box>
  );
};
