import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { NavigationContext } from '../../../components/Context/NavigationContext';
import { NavigationSteps } from '../../../components/Context/NavigationProvider';
import {
  verifyAbsenceDocument,
  verifyAbsenceDocumentResult,
} from '../../../features/declaration/services/verify_absence_document.service';
import { RouterConfig } from '../../../config/RouterConfig';
import { DeclarationDocumentNoFile } from './DeclarationDocumentNoFile';
import './DeclarationDocumentPage.scss';
import { AccountContext, useAppSelector } from '@/store';
import { Button } from '@/components/Button';
import { Spacer } from '@/components/Spacer';
import { InputFileHidden, InputFileScanbot } from '@/components/InputFile';
import { KerijLoadingOCR } from '@/components/base';
import { getTypeAbsence } from '@/features/declaration/services/get_type_absence.service';
import { DocumentStatus, OCRAPIResponse } from '@/types';
import { useToast } from '@/components/Toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Box, Tooltip, Typography } from '@mui/material';
import { ActionButton } from '@/components/ActionButton';
import { CardButton } from '@/components/CardButton';
import remiseDoc from '../../../assets/remise-doc.png';
import imgDemat from '../../../assets/Arret_demat.jpg';
import { usePlatform } from '@/utils/platform.utils';
import { StepProgressBar } from '@/features/declaration/components/bars';
import { useAppVariant } from '@/hooks/useAppVariant';

export const DeclarationDocumentUI = () => {
  const platform = usePlatform();
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useAppSelector(AccountContext.getUser);
  const document = location.state?.document || {};
  const absenceType = document?.absenceType?.value;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [closeLoading, setCloseLoading] = useState(false);
  const [documentData, setDocumentData] = useState<{ data: OCRAPIResponse }>();

  const [files, setFiles] = useState<File[]>(document?.files?.length > 0 ? document.files : []);

  const [isNoFileActive, setIsNoFileActive] = useState<boolean>(document?.files?.length === 0);
  const file = files.at(0);
  const { setCurrentStep } = useContext(NavigationContext);
  const isUpdate = location.state?.isUpdate || false;

  const onCloseLoader = async () => {
    setIsLoading(false);

    if (documentData == null)
      return rejectDocument("Nous n'avons pas réussi à valider votre document, vérifiez la qualité de votre document");

    navigate('/declaration-questions-contact', {
      state: {
        ...location.state,
        document: {
          ...document,
          file: file,
          ocr_result: documentData,
          status: 'ok',
          valueDocument: documentData,
        },
      },
    });
  };

  const handleNoFileClick = () => {
    setIsNoFileActive(true);
    setFiles([]);
    location.state.document.ocr_result = null;
  };

  const rejectDocument = (message: string) => {
    setIsLoading(false);
    toast.present({ message, severity: 'error' });
    navigate('/declaration-document', {
      state: {
        ...location.state,
        document: {
          ...location.state.document,
          ocr_result: { data: { documentStatus: DocumentStatus.REJECTED } },
          status: 'fail',
          valueDocument: { data: { documentStatus: DocumentStatus.REJECTED } },
        },
      },
    });
  };

  const handleVerification = async (
    verificationFunction: () => Promise<
      { success: true; data: { transactionId: string } } | { success: false; error: string }
    >,
  ) => {
    try {
      setIsLoading(true);
      const valueDocument = await verificationFunction();

      if (user == null) {
        toast.present({ message: `Impossible de récupérer l'utilisateur`, severity: 'error' });
        return;
      }

      if (valueDocument.success) {
        const typeAbsence = await getTypeAbsence({
          description: location.state?.document?.absenceType?.value,
          description_1: location.state?.document?.absenceType1?.value,
          description_2: location.state?.document?.absenceType2?.value,
        });

        const result = await verifyAbsenceDocumentResult({
          id_employee_information: user.employee_information.id,
          date_end: location.state?.document?.dateFin,
          date_start: location.state.document?.lastDayWork ?? location.state.document?.dateDebut,
          id_type_absence: typeAbsence.id ?? location.state?.document?.absenceType.value,
          transactionId: valueDocument.data.transactionId,
        });

        if (result.success === false) return rejectDocument(result.error);
        else {
          if (result.data.data.documentStatus === DocumentStatus.REJECTED)
            return rejectDocument("Votre document n'a pas pu être validé");
          else {
            setDocumentData(result.data);
            setCloseLoading(true);
          }
        }
        return;
      } else return rejectDocument('Une erreur est survenue lors de la validation, vérifier votre connexion');
    } catch {
      setIsLoading(false);
      setFiles([]);
      toast.present({ message: `Une erreur est survenue à la validation du document`, severity: 'error' });
    }
  };

  const handleSubmit = async () => {
    if (isNoFileActive) {
      navigate('/declaration-questions-contact', {
        state: {
          ...location.state,
          document: {
            ...document,
            file: file,
          },
          transactionId: null,
          status: 'fail',
        },
      });
    } else {
      if (user && files.length > 0) {
        const absenceType = document.absenceType?.value;
        setIsLoading(true);

        if (absenceType === '1' || absenceType === '2' || absenceType === '3') {
          handleVerification(async () => {
            return await verifyAbsenceDocument({ file: file as File });
          });
        } else {
          setIsLoading(false);
          navigate('/declaration-questions-contact', {
            state: {
              ...location.state,
              document: {
                ...document,
                file: file,
              },
              status: 'ok',
              transactionId: null,
            },
          });
        }
      }
    }
  };

  useEffect(() => {
    setCurrentStep(NavigationSteps.DECLARATION_DOCUMENT);
    if (document.absenceType?.value === '4' && document.relationEnfant.value === 'pere') {
      navigate(RouterConfig.declarationInformationPage().path, {
        state: location.state,
      });
    }
  }, []);

  // Auto submit form when one file is selected
  useEffect(() => {
    if (files.length > 0) {
      handleSubmit();
    }
  }, [files]);

  const [openScan, setOpenScan] = React.useState<boolean>(false);
  const inputFileRef = React.useRef<HTMLInputElement>(null);

  const appVariant = useAppVariant();

  return isLoading ? (
    <KerijLoadingOCR close={closeLoading} onClose={onCloseLoader} />
  ) : (
    <Box width={'100%'} display={'flex'} justifyContent={'center'}>
      <Box maxWidth={1080} width={'100%'}>
        <Box pl={appVariant.small ? 2 : 4} pr={appVariant.small ? 2 : 4}>
          <Spacer spacing={appVariant.medium ? 8 : 2} />
          <StepProgressBar type={absenceType} />
          <Spacer spacing={4} />
        </Box>

        {!isLoading && (
          <div>
            <Typography
              textAlign={'center'}
              fontWeight={600}
              fontSize={appVariant.small ? 32 : 24}
              lineHeight={'normal'}
            >
              Vous allez maintenant joindre votre justificatif.
            </Typography>

            <Spacer spacing={4} />

            <div className="row">
              <Tooltip
                placement="right-start"
                title={
                  <>
                    <Typography fontSize={12} fontWeight="bold" mb={1}>
                      <FontAwesomeIcon icon={faInfoCircle} style={{ marginRight: '0.5rem' }} />
                      Utilisez l&apos;appareil photo de votre smartphone
                    </Typography>
                    <Typography fontSize={12}>
                      Si vous disposez d’un arrêt de travail au format papier, nous vous recommandons de saisir votre
                      absence sur votre SMARTPHONE.
                    </Typography>
                  </>
                }
              >
                <div className="col-12 col-xs-12 offset-xs-0 col-sm-8 offset-sm-2 offset-lg-3 col-lg-6">
                  <CardButton
                    AvatarProps={{ src: remiseDoc }}
                    disabled={!platform.isMobile}
                    onClick={() => setOpenScan(true)}
                  >
                    <Typography fontSize={16} fontWeight={600} mb={0.5}>
                      Mon médecin m&apos;a remis un arrêt au format papier
                    </Typography>
                    <Typography fontSize={12}>Vous allez utiliser notre outil de scan</Typography>
                  </CardButton>
                </div>
              </Tooltip>

              <Spacer spacing={2} />

              <div className="col-12 col-xs-12 offset-xs-0 col-sm-8 offset-sm-2 offset-lg-3 col-lg-6">
                <CardButton AvatarProps={{ src: imgDemat }} onClick={() => inputFileRef.current?.click()}>
                  <Typography fontSize={16} fontWeight={600} mb={0.5}>
                    Vous disposez d&apos;un arrêt dématérialisé
                  </Typography>
                  <Typography fontSize={12}>Vous allez déposer un document (au format PDF de préférence)</Typography>
                </CardButton>
              </div>
            </div>

            <Box display={'flex'} justifyContent={'center'} width={'100%'}>
              <form onSubmit={() => handleSubmit()} style={{ maxWidth: 420, width: '100%' }}>
                <InputFileHidden
                  ref={inputFileRef}
                  onChange={(_, files) => {
                    setIsNoFileActive(false);
                    setFiles(files);
                  }}
                  accept="image/jpeg, image/png, application/pdf"
                  capture={false}
                  name="document"
                  id="file"
                />

                <InputFileScanbot
                  isOpen={openScan}
                  onClose={() => setOpenScan(false)}
                  onChange={(_, files) => {
                    setIsNoFileActive(false);
                    setFiles(files);
                  }}
                />

                {!isUpdate && (
                  <DeclarationDocumentNoFile
                    isActive={isNoFileActive}
                    disabled={isLoading}
                    onClick={handleNoFileClick}
                  />
                )}

                <Spacer size="large" />
                <Spacer size="medium" />
                <ActionButton
                  fullWidth
                  startIcon={null}
                  type="submit"
                  variant="contained"
                  actionName="next"
                  disabled={!(isNoFileActive || files.length > 0) || isLoading}
                />
                <Spacer />
                <Button
                  fullWidth
                  color="secondary"
                  disabled={isUpdate || isLoading}
                  onClick={() => {
                    navigate(RouterConfig.declarationDatePage().path, {
                      state: location.state,
                    });
                  }}
                >
                  Précédent
                </Button>
                <Spacer spacing={3} />
              </form>
            </Box>
          </div>
        )}
      </Box>
    </Box>
  );
};
