/* eslint-disable unicorn/no-new-array */
/* eslint-disable unicorn/no-useless-spread */
import React, { useContext, useEffect } from 'react';
import './stepProgressBar.scss';
import { NavigationContext } from '../../../../components/Context/NavigationContext';
import { NavigationSteps } from '../../../../components/Context/NavigationProvider';
import { UserInterface } from '../../../../types/user.interface';
import { useLocation, useNavigate } from 'react-router-dom';
import { getToken } from '../../../../utils/auth.utils';

const enum StepProgressBarType {
  Default = '0',
  Absence = '1',
  Hospi = '2',
  Mother = '3',
  Father = '4',
}

const getMaxStep = (step: StepProgressBarType) => {
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (step) {
    case StepProgressBarType.Default: {
      return 1;
    }
    default: {
      return NavigationSteps.DECLARATION_VALIDATION;
    }
  }
};

interface StepProgressBarProperties {
  type?: StepProgressBarType;
}

export const StepProgressBar: React.FC<StepProgressBarProperties> = ({
  type = StepProgressBarType.Default,
}: StepProgressBarProperties) => {
  const { currentStep } = useContext(NavigationContext);
  const navigate = useNavigate();
  const token = getToken();
  const location = useLocation();
  useEffect(() => {
    if (!token) {
      navigate('/login', { state: { ...location.state, currentStep, fromPath: '/declaration' } });
    }
  }, [currentStep, token]);

  const maxStep = getMaxStep(type);
  return (
    <div className="progresses mt-3">
      {[...new Array(maxStep)].map((value, index) => {
        if (index + 1 < currentStep) {
          return (
            <div className="steps-valid" key={index}>
              <span>.</span>
            </div>
          );
        } else if (index + 1 === currentStep) {
          return (
            <div className="steps-active" key={index}>
              <span>{currentStep}</span>
            </div>
          );
        } else {
          return (
            <div className="steps" key={index}>
              <span>.</span>
            </div>
          );
        }
      })}
    </div>
  );
};
