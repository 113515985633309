import React from 'react';
import { DefaultLayout } from '@/components/layouts';
import { DeclarationUI } from './DeclarationUI';

export const DeclarationPage: React.FC = () => {
  return (
    <DefaultLayout>
      <DeclarationUI />
    </DefaultLayout>
  );
};
