import React, { ForwardedRef } from 'react';
import { styled, Tab as MUITab, Tabs as MUITabs, TabsProps, tabClasses } from '@mui/material';
import classNames from 'clsx';

const componentName = 'PageTab';
export type PageTabsValue = string | null;

export interface PageTabProps extends Omit<TabsProps, 'value' | 'onChange' | 'children'> {
  value: PageTabsValue;
  items: Array<{ value: PageTabsValue; label: React.ReactNode }>;
  onChange?: (_: React.SyntheticEvent, value: PageTabsValue) => void;
}

export const PageTab = React.forwardRef(function PageTab(props: PageTabProps, ref: ForwardedRef<HTMLDivElement>) {
  const { className, items, ...tabsProps } = props;

  return (
    <Tabs ref={ref} className={classNames(componentName, className)} {...tabsProps}>
      {items.map((item) => (
        <Tab key={item.value} {...item} />
      ))}
    </Tabs>
  );
});

const Tabs = styled(MUITabs)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  '& .MuiTabs-indicator': { height: 1 },
}));

const Tab = styled(MUITab)(({ theme }) => ({
  [`&.${tabClasses.textColorPrimary}`]: {
    padding: theme.spacing(2),
    fontSize: 16,
    lineHeight: 1.5,
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    borderBottom: '1px solid',
    borderColor: theme.shape.borderColorVariant,
  },
  [`&.${tabClasses.selected}`]: {
    fontWeight: 600,
    borderBottom: 'none',
  },
}));
