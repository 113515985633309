import React from 'react';
import { DefaultLayout } from '@/components/layouts';
import { DeclarationContactUI } from './DeclarationContactUI';

export const DeclarationContactPage: React.FC = () => {
  return (
    <DefaultLayout>
      <DeclarationContactUI />
    </DefaultLayout>
  );
};
