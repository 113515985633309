import { NavigationContext } from '../../../components/Context/NavigationContext';

import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { StepProgressBar } from '../../../features/declaration/components/bars/StepProgressBar';
import { NavigationSteps } from '../../../components/Context/NavigationProvider';
import { RouterConfig } from '../../../config/RouterConfig';
import { useAppSelector } from '@/store';
import { Button } from '@/components/Button';
import { Spacer } from '@/components/Spacer';
import { ActionButton } from '@/components/ActionButton';
import { AppSelect } from '@/components/AppSelect';
import { Box, Typography } from '@mui/material';
import { useAppVariant } from '@/hooks/useAppVariant';

export const DeclarationProlongationUI = () => {
  const appVariant = useAppVariant();
  const location = useLocation();
  const navigate = useNavigate();

  const document = location.state?.document || {};
  const absenceType = document?.absenceType?.value;
  const [stateAbsence, setStateAbsence] = useState<string>(document.stateAbsence ? document.stateAbsence.value : '1');
  const { setCurrentStep } = useContext(NavigationContext);

  const user = useAppSelector((state) => state.accountContext);

  const handleClickNext = () => {
    if (user) {
      navigate(RouterConfig.declarationType2Page().path, {
        state: {
          document: {
            ...document,
            stateAbsence: { value: stateAbsence },
          },
        },
      });
    } else {
      navigate('/login', { state: { ...location.state, fromPath: '/declaration' }, replace: true });
    }
  };
  useEffect(() => {
    setCurrentStep(NavigationSteps.DECLARATION_PROLONGATION);
  }, []);
  return (
    <Box display={'flex'} justifyContent={'space-around'}>
      <Box maxWidth={1080} width={'100%'}>
        <Box pl={appVariant.small ? 2 : 4} pr={appVariant.small ? 2 : 4}>
          <Spacer spacing={appVariant.medium ? 8 : 2} />
          <StepProgressBar type={absenceType} />
          <Spacer spacing={4} />

          <Typography textAlign={'center'} fontWeight={600} fontSize={appVariant.small ? 32 : 24} lineHeight={'normal'}>
            {"C'est un(e)"}
          </Typography>

          <Spacer spacing={4} />

          <Box display={'flex'} justifyContent={'center'}>
            <form onSubmit={handleClickNext} style={{ maxWidth: 420, width: '100%' }}>
              <AppSelect
                dense
                items={[
                  { value: '1', label: 'Arrêt Initial' },
                  { value: '2', label: 'Prolongation' },
                ]}
                value={stateAbsence}
                onChange={(_, value) => setStateAbsence(value)}
              />

              <Spacer size="medium" />
              <ActionButton startIcon={null} type="submit" variant="contained" actionName="next" fullWidth />
              <Spacer />
              <Button color="secondary" onClick={() => navigate(RouterConfig.declarationPage().path)} fullWidth>
                Précédent
              </Button>
            </form>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
