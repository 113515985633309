import React from 'react';
import { Box, BoxProps, Divider, Typography } from '@mui/material';
import { Spacer } from '@/components/Spacer';
import { EmployeeSectionCard } from '@/components/EmployeeSectionCard';
import { LockOutlined, VisibilityRounded } from '@mui/icons-material';

export interface ProfilFormUIDesktopHelperProps extends BoxProps {}

export function ProfilFormUIDesktopHelper(props: ProfilFormUIDesktopHelperProps) {
  const { ...boxProps } = props;

  return (
    <Box {...boxProps}>
      <EmployeeSectionCard padding={2}>
        <Typography fontSize={48} lineHeight={0.5}>
          <LockOutlined />
        </Typography>

        <Spacer spacing={3} />

        <Typography fontSize={16} lineHeight={1.25} fontWeight={600}>
          Quelles informations peuvent être modifiées ?
        </Typography>

        <Spacer spacing={3} />

        <Typography fontSize={14} lineHeight={1.4}>
          Vous pouvez modifier votre adresse e-mail et votre numéro de téléphone directement depuis votre profil.
          Cependant, pour modifier votre adresse postale, il est nécessaire de contacter votre entreprise : elle se
          chargera de faire ce changement pour vous.
        </Typography>

        <Spacer spacing={3} />
        <Divider />
        <Spacer spacing={3} />

        <Typography fontSize={48} lineHeight={0.5}>
          <VisibilityRounded />
        </Typography>
        <Spacer spacing={3} />

        <Typography fontSize={16} lineHeight={1.25} fontWeight={600}>
          Qui peut accéder aux informations de mon profil ?
        </Typography>

        <Typography fontSize={14} lineHeight={1.4}>
          Toutes les informations renseignées dans votre profil sont strictement confidentielles et ne sont partagées
          qu&apos;avec votre entreprise.
        </Typography>
      </EmployeeSectionCard>
    </Box>
  );
}
