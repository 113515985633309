import React from 'react';
import { Navbar, NavbarMobile } from '@/components/Navbar';
import Footer from '../Footer/Footer';
import './PolicyLayout.scss';
import { useAppVariant } from '@/hooks/useAppVariant';
import { DefaultLayoutMobileBack } from './DefaultLayoutMobileBack';
import { useLocation } from 'react-router-dom';
import isAuthorized from '@/hooks/isAuthorized';

export interface PolicyLayoutProps extends React.ComponentProps<'div'> {
  title: string;
  date: string;
  withNavigation?: boolean;
}
export const PolicyLayout = ({ children, title, date, withNavigation = true }: PolicyLayoutProps) => {
  const appVariant = useAppVariant();
  const location = useLocation();

  const isLogin = React.useMemo(() => isAuthorized(location.pathname), [location.pathname]);

  return (
    <>
      {withNavigation && <Navbar isLogin={isLogin} />}
      {!appVariant.medium && <DefaultLayoutMobileBack />}
      <div className="policy-layout">
        <div className="policy-layout-header">
          <h1>{title}</h1>
          <h4>DERNIERE MISE A JOUR : {date}</h4>
        </div>
        <div className="policy-layout-content">{children}</div>
      </div>
      {withNavigation && <NavbarMobile />}
    </>
  );
};
