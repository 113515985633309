import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useAppVariant } from '@/hooks/useAppVariant';
import { Spacer } from '@/components/Spacer';
import { PageTab, PageTabProps, PageTabsValue } from '@/components/PageTab';
import { useLocation, useNavigate } from 'react-router-dom';
import { AbsenceQuery } from '@/query/AbsenceQuery';
import moment from 'moment';
import { Absence, AbsenceStatus } from '@/types';
import { DossierUIContent } from './DossierUIContent';
import { useToast } from '@/components/Toast';
import isAuthorized from '@/hooks/isAuthorized';
import { fetchEmployeeProfile } from '@/services/employee';
import { AccountContext, useAppDispatch } from '@/store';
import { AxiosResponse } from 'axios';

export function DossierUI() {
  const location = useLocation();
  const appVariant = useAppVariant();
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const queryAbsence = AbsenceQuery.useIndex();

  const [tabValue, setTabValue] = React.useState<PageTabsValue>('pending');

  const absences = React.useMemo(() => {
    if (!queryAbsence.data) return { pending: [], finished: [], deleted: [] };
    // eslint-disable-next-line unicorn/no-array-reduce
    return queryAbsence.data.reduce(
      (acc: { pending: Absence[]; finished: Absence[]; deleted: Absence[] }, absence) => {
        if (isPendingAbsence(absence)) acc.pending.push(absence);
        else if (isFinishedAbsence(absence)) acc.finished.push(absence);
        else if (isDeletedAbsence(absence)) acc.deleted.push(absence);
        return acc;
      },
      { pending: [], finished: [], deleted: [] },
    );
  }, [queryAbsence.data]);

  const onTabChange: PageTabProps['onChange'] = (_, tab) => {
    setTabValue(tab);
  };

  React.useEffect(() => {
    if (queryAbsence.isError)
      toast.present({
        message: 'Une erreur est survenue durant la récupération des absences',
        severity: 'error',
      });
  }, [queryAbsence.isError]);

  const onLogout = (message: string, severity: 'success' | 'error' = 'error') => {
    dispatch(AccountContext.logout());
    toast.present({ message, severity });
    return navigate('/login', { state: { fromPath: '/profil' } });
  };

  async function findEmployee() {
    let profilValue: AxiosResponse;
    try {
      profilValue = await fetchEmployeeProfile();
      if (profilValue?.data?.last_change_password) return dispatch(AccountContext.actions.setUser(profilValue.data));
      else onLogout('Veuillez vous reconnectez');
    } catch {
      onLogout('Erreur de connexion au serveur');
    }
  }

  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    if (isAuthorized(location.pathname)) findEmployee();
    else navigate('/login', { state: { fromPath: '/dossier' } });
  }, []);

  return (
    <Box display={'flex'} justifyContent={'space-around'}>
      <Box maxWidth={1080} width={'100%'}>
        <Box pl={appVariant.small ? 2 : 4} pr={appVariant.small ? 2 : 4}>
          <Spacer spacing={appVariant.small ? 8 : 1} />

          <Typography fontWeight={600} fontSize={appVariant.small ? 32 : 24} lineHeight={'normal'}>
            Mon dossier
          </Typography>

          <Spacer spacing={4} />

          <Grid container spacing={12}>
            <Grid item xs={12} md={7}>
              <Typography fontSize={14} lineHeight={'normal'}>
                Nous conservons l’historique de vos absences et rendez-vous pendant 5 ans.
              </Typography>

              <Spacer spacing={appVariant.small ? 3 : 2} />

              <Typography fontSize={14} lineHeight={'normal'}>
                Votre compte et les informations qui y sont attachées sont automatiquement supprimées 3 mois après votre
                départ de l’entreprise.
              </Typography>

              <Spacer spacing={appVariant.small ? 2 : 1} />

              <Box>
                <PageTab
                  variant="fullWidth"
                  value={tabValue}
                  items={[
                    { label: 'En cours', value: 'pending' },
                    { label: 'Terminées', value: 'finished' },
                  ]}
                  onChange={onTabChange}
                />
              </Box>

              <Spacer spacing={appVariant.small ? 4 : 2} />

              <DossierUIContent value={absences} type={tabValue} loading={queryAbsence.isLoading} />
            </Grid>
          </Grid>
        </Box>

        <Spacer spacing={3} />
      </Box>
    </Box>
  );
}

function isPendingAbsence(absence: Absence) {
  if (absence.status === AbsenceStatus.DELETED) return false;
  const endDate = moment(absence.date_end ?? absence.date_end_hospi_theory).startOf('day');
  return endDate.isSameOrAfter(moment().startOf('day'));
}

function isFinishedAbsence(absence: Absence) {
  if (absence.status === AbsenceStatus.DELETED) return false;
  const endDate = moment(absence.date_end ?? absence.date_end_hospi_theory).startOf('day');
  return endDate.isBefore(moment().startOf('day'));
}

function isDeletedAbsence(absence: Absence) {
  return absence.status === AbsenceStatus.DELETED;
}
