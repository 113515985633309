import React from 'react';
import { Navbar, NavbarMobile } from '@/components/Navbar';
import Footer from '../Footer/Footer';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import 'popstate-direction';

function QuestionnaireRetourFin() {
  return (
    <div>
      <Navbar />
      <div className="container-fluid">
        <h3 className="question-form mt-5 mb-5 text-center">Questionnaire validé</h3>

        <form className="form-legacy form-declaration text-center col-12 col-md-8">
          <div className="declaration-check mb-5">
            <FontAwesomeIcon className="fa-5x" icon={faCheck} />
          </div>

          <div className="col-12">
            <p>
              Merci. <br /> Vos réponses sont transmises à votre employeur. <br />
              <br />
            </p>
          </div>
        </form>
        <div className="position-relative col-12 col-md-8 mt-5 mb-5">
          <Link className="btn-link" to="/">
            <button type="button" className="position-absolute top-0 start-50 translate-middle-x btn btn-kerij">
              Retour à l'accueil
            </button>
          </Link>
        </div>
      </div>
      <NavbarMobile />
      <Footer />
    </div>
  );
}

export default QuestionnaireRetourFin;
