import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Divider, Menu, MenuItem } from '@mui/material';
import { Menu as MenuIcon } from '@/components/AppIcon';
import { Button, type ButtonProps } from '@/components/Button';
import { useAppDispatch, AccountContext, useAppSelector } from '@/store';
import { UserAvatar } from '@/components/UserAvatar';

export interface NavbarMenuButtonProps extends ButtonProps {
  isLogin?: boolean;
}

export function NavbarMenuButton(props: NavbarMenuButtonProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isLogin = true, onClick, ...otherProps } = props;
  const [anchorEl, setAnchorEl] = React.useState<undefined | HTMLElement>();
  const user = useAppSelector(AccountContext.getUser);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    onClick?.(event);
  };
  const handleClose = () => {
    setAnchorEl(undefined);
  };
  const handleMenuItemClick = (callback: (event: React.MouseEvent<HTMLElement>) => void) => {
    return (event: React.MouseEvent<HTMLElement>): void => {
      handleClose();
      callback(event);
    };
  };

  return (
    <>
      <Button
        color="secondary"
        startIcon={<MenuIcon sx={{ fontSize: (theme) => theme.spacing(3) }} />}
        endIcon={<UserAvatar user={user} AvatarProps={{ size: 'medium' }} />}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        size="large"
        {...otherProps}
      />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {isLogin ? (
          <>
            <MenuItem
              component={Link}
              to="/profil"
              onClick={handleMenuItemClick((_event) => {
                // do nothing
              })}
            >
              Profil
            </MenuItem>
            <Divider />
            <MenuItem
              component={Link}
              to="/report"
              onClick={handleMenuItemClick((_event) => {
                // do nothing
              })}
            >
              Nous signaler un problème
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={handleMenuItemClick((_event) => {
                dispatch(AccountContext.logout());
                navigate('/');
              })}
            >
              Déconnexion
            </MenuItem>
          </>
        ) : (
          <DisconnectedMenu />
        )}
      </Menu>
    </>
  );
}

const DisconnectedMenu = () => {
  const navigate = useNavigate();
  return <MenuItem onClick={() => navigate('/login')}>Se connecter</MenuItem>;
};
