import React from 'react';
import { NavbarMobileTabProps } from './NavbarMobileTab';
import { KERij } from '@/components/AppIcon';
import { Feature, UserInterface } from '@/types';
import { Badge } from '@mui/material';
import { DashboardOutlined, Event, SearchOutlined } from '@mui/icons-material';

/**
 * Défini le contenu du menu Mobile salarié
 */
export function defineMenu(
  user: UserInterface | null,
  options: { hasUnread: boolean; isLogin: boolean },
): NavbarMobileTabProps[] {
  return [
    ...(options.isLogin
      ? [
          {
            label: 'Services',
            feature: 'ServiceManagement' as keyof Feature,
            to: options.isLogin ? '/services' : '/login',
            icon: <SearchOutlined />,
          },
        ]
      : []),
    ...(options.isLogin
      ? [
          {
            label: 'Rendez-vous',
            feature: 'InterviewManagement' as keyof Feature,
            to: options.isLogin ? '/rdv' : '/login',
            icon: <Event />,
          },
        ]
      : []),

    ...(options.isLogin
      ? [
          {
            label: 'Déclarer',
            to: options.isLogin ? '/declaration' : '/login',
            icon: <KERij sx={{ padding: '2px' }} />, // FIXME
          },
        ]
      : []),
    ...(options.isLogin
      ? [
          {
            label: 'Activités',
            to: options.isLogin ? '/activites' : '/login',
            icon: (
              <Badge
                overlap="circular"
                color="warning"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                variant="dot"
                invisible={!options.hasUnread}
              >
                <DashboardOutlined />
              </Badge>
            ),
          },
        ]
      : []),
  ];
}
