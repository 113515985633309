import React from 'react';
import { ParcoursPublic } from '@/types';
import CustomModal, { CustomModalProps } from '@/components/base/CustomModal';
import { Box, Typography } from '@mui/material';
import { ActionButton } from '@/components/ActionButton';
import { Spacer } from '@/components/Spacer';

export interface RdvUIModalProps extends Pick<CustomModalProps, 'open' | 'onClose'> {
  value?: ParcoursPublic;
  disabled?: boolean;
  onSubmit?: () => void;
}

export function RdvUIModal(props: RdvUIModalProps) {
  const { value, open, disabled, onClose, onSubmit } = props;

  return (
    <CustomModal
      dense
      renderHeader={() => 'Attention'}
      open={open}
      onClose={onClose}
      renderContent={() => (
        <Box textAlign={'left'}>
          <Typography color="primary" fontWeight={600}>
            {value?.action}
          </Typography>
          <Spacer />
          <Typography color="primary">Êtes-vous certain de votre choix ?</Typography>
        </Box>
      )}
      renderFooter={() => (
        <ActionButton
          actionName="validate"
          variant="contained"
          color="primary"
          onClick={onSubmit}
          disabled={disabled}
        />
      )}
    />
  );
}
