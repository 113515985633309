/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import classNames from 'clsx';
import { Box, BoxProps } from '@mui/material';
import { Absence } from '@/types';
import { Spacer } from '../Spacer';
import { ActionButton } from '../ActionButton';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const componentName = 'EmployeeAbsenceCardActions';

export interface EmployeeAbsenceCardActionsProps extends Omit<BoxProps, 'children'> {
  value: Absence;
  hasDocument?: boolean;
}

export function EmployeeAbsenceCardActions(props: EmployeeAbsenceCardActionsProps) {
  const { value, hasDocument, className, ...boxProps } = props;

  const navigate = useNavigate();

  const action = hasDocument
    ? value.notifications?.find((notification) => notification.type_notification === "Notification de suivi de l'arret")
    : undefined;

  const updateDocument = () => {
    const typeAbsence = value.type_absence;
    navigate(`/declaration-document`, {
      state: {
        document: {
          absenceType: {
            value: typeAbsence.description + '',
            label: typeAbsence.description_label,
          },
          absenceType1: {
            value: typeAbsence.description_1 + '',
            label: typeAbsence.description_1_label,
          },
          absenceType2: {
            value: typeAbsence.description_2 + '',
            label: typeAbsence.description_2_label,
          },
          dateDebut: moment(value.date_start).format('YYYY-MM-DD'),
          dateFin: moment(value.date_end).format('YYYY-MM-DD'),
        },
        isUpdate: true,
        absenceToUpdate: {
          id: value.id,
          // @ts-ignore Type incorrect
          dateAction: value.date_action,
          // @ts-ignore Type incorrect
          absences: value.absences,
        },
      },
    });
  };

  return (
    <Box className={classNames(componentName, className)} {...boxProps}>
      {!hasDocument && (
        <Box>
          <Spacer spacing={4} />
          <ActionButton color="secondary" label={'Déposer un justificatif'} onClick={updateDocument} />
        </Box>
      )}

      {action && (
        <Box>
          <Spacer spacing={2.5} />
          <ActionButton href={action.url} color="secondary" label={'Gérer mon retour'} />
        </Box>
      )}
    </Box>
  );
}
