import React from 'react';
import classNames from 'clsx';
import { styled, CardProps, Card as MUICard, Box, useTheme } from '@mui/material';

const componentName = 'EmployeeSectionCard';

export interface EmployeeSectionCardProps extends Omit<CardProps, 'color' | 'style'> {
  color?: 'default' | 'success' | 'warning' | 'disabled' | 'variant';
  padding?: number;
}

export function EmployeeSectionCard(props: EmployeeSectionCardProps) {
  const { className, color = 'default', padding = 2.5, children, ...cardProps } = props;

  const theme = useTheme();

  const backgroundColor = () => {
    if (color === 'success') return theme.palette.sageMeadow[50];
    if (color === 'warning') return theme.palette.sunsetEmber[50];
    if (color === 'variant') return theme.palette.aquaSerenity[50];
    if (color === 'disabled') return theme.palette.silverVeil[100];
    return theme.md3.sys.color.surface;
  };

  const textColor = () => {
    if (color === 'success') return theme.palette.sageMeadow[900];
    if (color === 'warning') return theme.palette.sunsetEmber[950];
    if (color === 'variant') return theme.palette.aquaSerenity[950];
    if (color === 'disabled') return theme.palette.silverVeil[900];
    return theme.md3.sys.color.onSurface;
  };

  return (
    <Card
      elevation={1}
      className={classNames(componentName, className)}
      style={{ backgroundColor: backgroundColor(), color: textColor() }}
      {...cardProps}
    >
      <Box p={padding}>{children}</Box>
    </Card>
  );
}

const Card = styled(MUICard)(({ theme }) => ({
  padding: 0,
  border: '1px solid',
  borderColor: theme.shape.borderColorVariant,
}));
