import React from 'react';
import { DefaultLayout } from '@/components/layouts';
import { DeclarationDocumentUI } from './DeclarationDocumentUI';

export const DeclarationDocumentPage: React.FC = () => {
  return (
    <DefaultLayout>
      <DeclarationDocumentUI />
    </DefaultLayout>
  );
};
