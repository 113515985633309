import React from 'react';
import { DefaultLayout } from '../../../components/layouts';
import { DossierUI } from './DossierUI';

export const DossierPage: React.FC = () => {
  return (
    <DefaultLayout mobileBackButton>
      <DossierUI />
    </DefaultLayout>
  );
};
