import React from 'react';
import classNames from 'clsx';
import {
  styled,
  Box,
  AvatarProps,
  CardProps,
  Avatar as MUIAvatar,
  Card as MUICard,
  CardContent as MUICardContent,
} from '@mui/material';

const componentName = 'KRJCardImage';

export interface CardImageProps extends Pick<CardProps, 'className' | 'children'> {
  AvatarProps?: Pick<AvatarProps, 'src' | 'children'>;
  content?: React.ReactNode;
}

export function CardImage(props: CardImageProps) {
  const { className, children, content, AvatarProps } = props;

  return (
    <Card className={classNames(componentName, className)} elevation={4}>
      <Box display="flex">
        <CardContentContainer>
          <CardContent>
            <CardContentBox>{children}</CardContentBox>
          </CardContent>
        </CardContentContainer>

        {AvatarProps && <Avatar {...AvatarProps} />}
      </Box>

      <Box pl={2} pr={2} pt={1.5} height={'100%'}>
        <CardContentAdditional>{content}</CardContentAdditional>
      </Box>
    </Card>
  );
}

const Card = styled(MUICard)(({ theme }) => ({
  backgroundColor: theme.md3.sys.color.surface,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  paddingTop: theme.spacing(1.5),
}));

const CardContentBox = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1.5),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}));

const CardContent = styled(MUICardContent)(({ theme }) => ({
  flex: '1 0 auto',
  padding: '0 !important',
}));

const CardContentAdditional = styled(MUICardContent)(({ theme }) => ({
  padding: '0 !important',
  height: '100%',
}));

const CardContentContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));

const Avatar = styled(MUIAvatar)(({ theme }) => ({
  width: theme.spacing(10),
  height: theme.spacing(10),
  marginRight: theme.spacing(2),
  backgroundColor: theme.md3.sys.color.white,
}));
