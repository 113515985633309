import React from 'react';
import classNames from 'clsx';
import { EmployeeSectionCardProps, EmployeeSectionCard } from '../EmployeeSectionCard';
import { Box, Skeleton, Typography } from '@mui/material';
import { Spacer } from '../Spacer';

const componentName = 'EmployeeAbsenceCardSkeleton';

export type EmployeeAbsenceCardSkeletonProps = Omit<EmployeeSectionCardProps, 'children'>;

export function EmployeeAbsenceCardSkeleton(props: EmployeeAbsenceCardSkeletonProps) {
  const { className, ...cardProps } = props;

  return (
    <EmployeeSectionCard className={classNames(componentName, className)} {...cardProps}>
      <Box display={'flex'} justifyContent={'space-between'} minHeight={28}>
        <Typography marginTop={'auto'} marginBottom={'auto'}>
          <Skeleton width={106} height={20} />
        </Typography>
      </Box>

      <Spacer spacing={2} />
      <Box display={'flex'} justifyContent={'space-between'} minHeight={20}>
        <Typography>
          <Skeleton width={80} height={32} />
        </Typography>
      </Box>

      <Spacer spacing={1} />

      <Box display={'flex'} justifyContent={'space-between'} minHeight={18}>
        <Typography>
          <Skeleton width={200} height={16} />
        </Typography>
      </Box>
    </EmployeeSectionCard>
  );
}
