import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ImgArret from '@/assets/arret_travail.jpg';
import ImgHospi from '@/assets/hospitalisation.jpg';
import ImgAccident from '@/assets/accident_du_travail.jpg';
import { RouterConfig } from '@/config/RouterConfig';
import { absenceType as absenceTypeConstant } from '@/features/dashboard-rh/create-absence-rh/constants/absenceType.constant';
import { AccountContext, useAppSelector } from '@/store';
import { DeclarationDesktopWarning } from './DeclarationDesktopWarning';
import { Box, Grid, Typography } from '@mui/material';
import { CardButton } from '@/components/CardButton';
import { Spacer } from '@/components/Spacer';
import { useAppVariant } from '@/hooks/useAppVariant';

const getStateTransfer = (absenceType: number, absenceTypeLabel: string) => {
  switch (absenceType) {
    case absenceTypeConstant.arret_maladie: {
      return {
        document: {
          absenceType: { value: '1', label: 'Arrêt de travail' },
          absenceType1: { value: '1', label: 'Maladie' },
        },
      };
    }
    case absenceTypeConstant.accident_arret_professionnel: {
      return {
        document: {
          absenceType: { value: '1', label: 'Arrêt de travail' },
          absenceType1: {
            value: '2',
            label: 'Accident - Maladie professionnelle',
          },
        },
      };
    }
    default: {
      return {
        document: {
          absenceType: {
            value: absenceType.toString(),
            label: absenceTypeLabel,
          },
        },
      };
    }
  }
};

export const DeclarationUI = () => {
  const appVariant = useAppVariant();
  const user = useAppSelector(AccountContext.getUser);
  const isLoading = useAppSelector(AccountContext.isLoading);

  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState(false);
  const [arretTypeDeclaration, setArretTypeDeclaration] = useState<'MA' | 'HO' | 'AT'>();

  const startDeclaration = (type?: 'MA' | 'HO' | 'AT') => {
    const declarationType = type ?? arretTypeDeclaration;

    if (declarationType === 'MA') {
      return navigate(RouterConfig.declarationType1Page().path, {
        state: getStateTransfer(absenceTypeConstant.arret_maladie, 'Maladie'),
      });
    }
    if (declarationType === 'HO') {
      return navigate(RouterConfig.declarationType1Page().path, {
        state: getStateTransfer(absenceTypeConstant.hospitalisation, 'Hospitalisation'),
      });
    }
    if (declarationType === 'AT') {
      return navigate(RouterConfig.declarationType1Page().path, {
        state: getStateTransfer(absenceTypeConstant.accident_arret_professionnel, 'Accident/Maladie professionnelle'),
      });
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  useEffect(() => {
    if (user == null && !isLoading) navigate('/login', { replace: true, state: { fromPath: '/declaration' } });
  }, [user, isLoading]);

  return (
    <Box display={'flex'} justifyContent={'space-around'}>
      <Box maxWidth={1080} width={'100%'}>
        <Box pl={appVariant.small ? 2 : 4} pr={appVariant.small ? 2 : 4}>
          <Spacer spacing={appVariant.small ? 8 : 4} />

          <Typography
            textAlign={appVariant.small ? 'left' : 'center'}
            fontWeight={600}
            fontSize={appVariant.small ? 32 : 24}
            lineHeight={'normal'}
          >
            KERij simplifie vos démarches
          </Typography>

          <Spacer spacing={4} />

          <Grid container spacing={2}>
            {/* ARRET MALADIE */}
            <Grid item xs={12} sm={6}>
              <CardButton
                AvatarProps={{ src: ImgArret }}
                onClick={() => {
                  if (user == null) navigate('/login', { replace: true, state: { fromPath: '/declaration' } });
                  else if (appVariant.small) {
                    setArretTypeDeclaration('MA');
                    setOpenModal(true);
                  } else {
                    startDeclaration('MA');
                  }
                }}
              >
                <Typography fontSize={12} mb={0.5} lineHeight={'normal'}>
                  Déclarer
                </Typography>
                <Typography fontSize={16} fontWeight={600} lineHeight={'normal'}>
                  Un arrêt maladie
                </Typography>
              </CardButton>
            </Grid>

            {/* HOSPITALISATION */}
            <Grid item xs={12} sm={6}>
              <CardButton
                AvatarProps={{ src: ImgHospi }}
                onClick={() => {
                  if (user == null) navigate('/login', { replace: true, state: { fromPath: '/declaration' } });
                  else if (appVariant.small) {
                    setArretTypeDeclaration('HO');
                    setOpenModal(true);
                  } else {
                    startDeclaration('HO');
                  }
                }}
              >
                <Typography fontSize={12} mb={0.5} lineHeight={'normal'}>
                  Déclarer
                </Typography>
                <Typography fontSize={16} fontWeight={600} lineHeight={'normal'}>
                  Une hospitalisation
                </Typography>
              </CardButton>
            </Grid>

            {/* ACCIDENT DE TRAVAIL */}
            <Grid item xs={12} sm={6}>
              <CardButton
                AvatarProps={{ src: ImgAccident }}
                onClick={() => {
                  if (user == null) navigate('/login', { replace: true, state: { fromPath: '/declaration' } });
                  else if (appVariant.small) {
                    setArretTypeDeclaration('AT');
                    setOpenModal(true);
                  } else {
                    startDeclaration('AT');
                  }
                }}
              >
                <Typography fontSize={12} mb={0.5} lineHeight={'normal'}>
                  Déclarer
                </Typography>
                <Typography fontSize={16} fontWeight={600} lineHeight={'normal'}>
                  Un accident de travail
                </Typography>
              </CardButton>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <DeclarationDesktopWarning open={openModal} onClose={() => setOpenModal(false)} onConfirm={startDeclaration} />
    </Box>
  );
};
