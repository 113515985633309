import React from 'react';
import { DefaultLayout } from '@/components/layouts';
import { DeclarationType2UI } from './DeclarationType2UI';

export const DeclarationType2Page: React.FC = () => {
  return (
    <DefaultLayout>
      <DeclarationType2UI />
    </DefaultLayout>
  );
};
