import React from 'react';
import classNames from 'clsx';
import {
  styled,
  Box,
  AvatarProps,
  CardProps,
  CardActionAreaProps,
  Avatar as MUIAvatar,
  Card as MUICard,
  CardActionArea as MUICardActionArea,
  CardContent as MUICardContent,
  Typography,
  Collapse,
} from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';

const componentName = 'KRJCardButton';

export interface CardButtonProps
  extends Pick<CardProps, 'className'>,
    Pick<CardActionAreaProps, 'disabled' | 'onClick' | 'children'> {
  AvatarProps?: Pick<AvatarProps, 'src' | 'children'>;
  content?: React.ReactNode;
}

export function CardButton(props: CardButtonProps) {
  const { className, children, content, onClick, AvatarProps, ...actionProps } = props;
  const [openContent, setOpenContent] = React.useState(false);

  const contentRef = React.useRef<HTMLDivElement>(null);

  const handleClick: CardActionAreaProps['onClick'] = (event) => {
    if (content) setOpenContent((open) => !open);
    onClick?.(event);
  };

  React.useEffect(() => {
    if (openContent && contentRef.current)
      setTimeout(() => contentRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' }), 100);
  }, [openContent]);

  return (
    <Card
      className={classNames(componentName, className)}
      elevation={4}
      sx={{ opacity: actionProps.disabled ? 0.75 : undefined }}
    >
      <CardActionArea disableRipple disableTouchRipple onClick={handleClick} {...actionProps}>
        <Box display="flex">
          <CardContentContainer style={actionProps.disabled ? { opacity: 0.5 } : undefined}>
            <CardContent>
              <Box>{children}</Box>
            </CardContent>
            {content && (
              <Box display="flex" mt={3}>
                <KeyboardArrowDown
                  sx={{
                    fontSize: 18,
                    transform: openContent ? 'rotate(180deg)' : 'rotate(0)',
                    transition: 'transform 0.3s',
                  }}
                />
                <Typography ml={1} fontSize={12} fontWeight={600}>
                  Voir plus
                </Typography>
              </Box>
            )}
          </CardContentContainer>

          {AvatarProps && <Avatar {...AvatarProps} />}
        </Box>
      </CardActionArea>

      {content && (
        <Collapse in={openContent} timeout="auto" unmountOnExit>
          <Box pl={2} pr={2}>
            <CardContentAdditional ref={contentRef}>{content}</CardContentAdditional>
          </Box>
        </Collapse>
      )}
    </Card>
  );
}

const Card = styled(MUICard)(({ theme }) => ({
  backgroundColor: theme.md3.sys.color.surface,
}));

const CardActionArea = styled(MUICardActionArea)(({ theme }) => ({
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1.5),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
}));

const CardContent = styled(MUICardContent)(({ theme }) => ({
  flex: '1 0 auto',
  padding: '0 !important',
}));

const CardContentAdditional = styled(MUICardContent)(({ theme }) => ({
  padding: '0 !important',
}));

const CardContentContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));

const Avatar = styled(MUIAvatar)(({ theme }) => ({
  width: theme.spacing(10),
  height: theme.spacing(10),
  marginRight: theme.spacing(2),
  backgroundColor: theme.md3.sys.color.white,
}));
