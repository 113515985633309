import React from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  type PaperProps,
  Divider,
  Fade,
  Paper,
  styled,
  Unstable_TrapFocus,
  type DialogProps,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  ListSubheader,
  Typography,
} from '@mui/material';
import { AccountContext, useAppDispatch, useAppSelector } from '@/store';
import { BrandIcon } from '@/components/BrandIcon';
import classNames from 'clsx';
import { IconButton } from '@/components/IconButton';
import { Close } from '@/components/AppIcon';
import { FooterLinks } from '@/components/Footer';
import { Button } from '@/components/Button';
import { UserAvatar } from '@/components/UserAvatar';
import { ChevronRight, Feedback } from '@/components/AppIcon';

const componentName = 'NavbarMobileMenu';

const renderSection = (content: React.ReactNode) => <Box className={`${componentName}__Section`}>{content}</Box>;
const renderDivider = () => renderSection(<Divider sx={{ margin: 2 }} />);
const renderRightArrow = () => {
  return <ChevronRight />;
};

export interface NavbarMobileMenuProps extends PaperProps, Pick<DialogProps, 'open' | 'onClose'> {}

export function NavbarMobileMenu(props: NavbarMobileMenuProps) {
  const dispatch = useAppDispatch();
  const user = useAppSelector(AccountContext.getUser);
  const { className, onClose, open, ...otherProps } = props;

  return user == null ? null : (
    <Unstable_TrapFocus open disableAutoFocus disableEnforceFocus>
      <Fade appear={false} in={open}>
        <Root
          role="dialog"
          aria-modal="true"
          aria-label="Menu principal"
          square
          variant="outlined"
          tabIndex={-1}
          className={classNames(componentName, className)}
          {...otherProps}
        >
          <Box className={`${componentName}__Header`}>
            <BrandIcon className={`${componentName}__BrandIcon`} />
            <Box sx={{ flexGrow: 1 }} />
            <IconButton
              className={`${componentName}__CloseButton`}
              icon={<Close />}
              onClick={(event) => onClose?.(event, 'backdropClick')}
            />
          </Box>
          <Divider />
          <Box className={`${componentName}__Content`}>
            {renderSection(
              <List sx={{ width: '100%' }} component="nav">
                <ListItem disablePadding secondaryAction={renderRightArrow()}>
                  <ListItemButton component={Link} to={'/profil'} onClick={() => onClose?.({}, 'escapeKeyDown')}>
                    <ListItemAvatar>
                      <UserAvatar user={user} AvatarProps={{ size: 'large' }} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={`${user.employee_information.firstname} ${user.employee_information.lastname}`}
                      secondary={'Afficher le profil'}
                    />
                  </ListItemButton>
                </ListItem>
              </List>,
            )}
            {renderDivider()}
            {renderSection(
              <List
                sx={{ width: '100%' }}
                component="nav"
                aria-labelledby="assistance-header"
                subheader={
                  <ListSubheader component="div" id="assistance-header">
                    <Typography
                      sx={(theme) => ({
                        ...theme.md3.sys.typescale.title.large,
                        color: theme.md3.sys.color.onPrimary,
                        paddingTop: theme.spacing(2),
                        paddingBottom: theme.spacing(3.5),
                      })}
                    >
                      Assistance
                    </Typography>
                  </ListSubheader>
                }
              >
                <ListItem disablePadding secondaryAction={renderRightArrow()}>
                  <ListItemButton component={Link} to={'/report'} onClick={() => onClose?.({}, 'escapeKeyDown')}>
                    <ListItemIcon>
                      <Feedback />
                    </ListItemIcon>
                    <ListItemText primary="Nous signaler un problème" />
                  </ListItemButton>
                </ListItem>
              </List>,
            )}
            {renderDivider()}
            {renderSection(
              <Button
                color="secondary"
                fullWidth
                onClick={() => dispatch(AccountContext.logout())}
                sx={{ marginTop: (theme) => theme.spacing(2.5) }}
              >
                Se déconnecter
              </Button>,
            )}
          </Box>
          <Box className={`${componentName}__Footer`}>
            <FooterLinks sx={{ paddingTop: 1, paddingBottom: 1 }} />
          </Box>
        </Root>
      </Fade>
    </Unstable_TrapFocus>
  );
}

const Root = styled(Paper, {
  name: componentName,
})(({ theme }) => ({
  flexDirection: 'column',
  display: 'flex',
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  top: 0,
  margin: 0,
  borderWidth: 0,
  zIndex: -1, // Under navbar
  paddingBottom: theme.spacing(8),

  [`& .${componentName}__Header`]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    // paddingTop: theme.spacing(3),
    // paddingBottom: theme.spacing(3),
    display: 'flex',
    flexDirection: 'row',
    height: theme.spacing(4 + 3 * 2),
    alignItems: 'center',
  },

  [`& .${componentName}__BrandIcon`]: {
    height: theme.spacing(4),
  },
  [`& .${componentName}__CloseButton`]: {
    margin: theme.spacing(-1),
  },
  [`& .${componentName}__Content`]: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(4),
    gap: theme.spacing(0.5), // Figma has a problem, it does not use list/list item and has many custom spacing (40px, 28px, etc)
  },
  [`& .${componentName}__Footer`]: {
    paddingBottom: theme.spacing(1),
  },
  [`& .${componentName}__Section`]: {
    paddingLeft: theme.spacing(2), // List has already spacing left and right
    paddingRight: theme.spacing(2),
  },
}));
