import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getToken } from '../../utils/auth.utils';

interface RequireLoginProps {
  children: React.ReactNode;
}

const RequireLogin = ({ children }: RequireLoginProps) => {
  const navigate = useNavigate();
  const token = getToken();

  const location = useLocation();
  useEffect(() => {
    if (!token) {
      navigate('/login', {
        state: { fromPath: location.pathname },
      });
    }
  }, [token]);

  return <Box>{children}</Box>;
};

export default RequireLogin;
