import React from 'react';
import ImgRdvEntretienLiaison from '@/assets/img-declaration-services.svg';
import ImgRdvEntretienRetour from '@/assets/img-rdv-managerial.svg';
import ImgRdvMedical from '@/assets/img-rdv-visite.svg';
import ImgRdvMedicalReprise from '@/assets/img-visite-prereprise.svg';
import { ParcoursPublic } from '@/types';
import { ActionButton } from '@/components/ActionButton';
import { Box, Tooltip, Typography } from '@mui/material';
import { CardButton } from '@/components/CardButton';
import { CardImage } from '@/components/CardImage';
import { useAppVariant } from '@/hooks/useAppVariant';

export interface RdvUIItemProps {
  value: ParcoursPublic;
  onTrigger?: () => void;
}

export function RdvUIItem(props: RdvUIItemProps) {
  const appVariant = useAppVariant();
  const { value, onTrigger } = props;

  const renderAction = () => {
    return (
      <Box mt="auto" pt={0.5} pb={1.5} textAlign={'right'}>
        <ActionButton
          actionName="next"
          label="Je fais une demande"
          onClick={onTrigger}
          disabled={value.parcours_id == null}
        />
      </Box>
    );
  };

  const renderContent = () => {
    return (
      <Box
        sx={
          appVariant.small
            ? {
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
              }
            : {}
        }
      >
        {value.description.map((line, key) => (
          <Typography
            key={key}
            fontSize={14}
            lineHeight={1.4}
            mb={2.5}
            sx={(theme) => ({ color: theme.md3.sys.color.onSurfaceVariant })}
          >
            {line}
          </Typography>
        ))}

        {value.parcours_id == null && (
          <Tooltip
            title={"Cet entretien n'a pas encore été paramétré par votre entreprise"}
            sx={{ position: 'relative' }}
          >
            {renderAction()}
          </Tooltip>
        )}

        {value.parcours_id != null && renderAction()}
      </Box>
    );
  };

  return appVariant.small ? (
    <CardImage AvatarProps={{ src: renderParcoursIcon(value.code) }} content={renderContent()}>
      <Typography fontSize={12} mb={0.5} lineHeight={'normal'}>
        Solliciter
      </Typography>
      <Typography fontSize={16} fontWeight={600} lineHeight={'normal'}>
        {value.title}
      </Typography>
    </CardImage>
  ) : (
    <CardButton AvatarProps={{ src: renderParcoursIcon(value.code) }} content={renderContent()}>
      <Typography fontSize={12} mb={0.5} lineHeight={'normal'}>
        Solliciter
      </Typography>
      <Typography fontSize={16} fontWeight={600} lineHeight={'normal'}>
        {value.title}
      </Typography>
    </CardButton>
  );
}

const renderParcoursIcon = (code: ParcoursPublic['code']) => {
  switch (code) {
    case 'demande_pour_un_entretien_de_liaison_a_linitiative_du_salarie': {
      return ImgRdvEntretienLiaison;
    }
    case 'demande_pour_un_entretien_de_retour_a_lemploi_a_linitiative_du_salarie': {
      return ImgRdvEntretienRetour;
    }
    case 'demande_de_visite_medicale_a_linitiative_du_salarie': {
      return ImgRdvMedical;
    }
    case 'demande_pour_une_visite_medicale_de_prereprise_a_linitiative_du_salarie': {
      return ImgRdvMedicalReprise;
    }
    default: {
      return ImgRdvEntretienLiaison;
    }
  }
};
