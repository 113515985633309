import React from 'react';
import { DefaultLayout } from '@/components/layouts';
import { ProfilFormUI } from './ProfilFormUI';

export const ProfilFormPage: React.FC = () => {
  return (
    <DefaultLayout mobileBackButton>
      <ProfilFormUI />
    </DefaultLayout>
  );
};
